import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import moment from 'moment-mini';

// Хелперы
import { subControl } from '../helpers';

// Перечисления
import { CarDocumentTypesEnum } from '../enums/car-document-types.enum';
import { CarIdentificatorsEnum } from '../enums/car-identificators.enum';

// Интерфейсы
import { IPropertyControls } from '../interface/property-controls.interface';
import { IDriver } from '../interface/driver.interface';

// Данные контролов
import PropertyControls from '../../../assets/data/property-controls.json';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    // Форма
    public form: UntypedFormGroup;
    // Подписка на форму общую
    public subscriptionForm: Subscription = new Subscription();
    // Подписка на форму carData
    public subscriptionCarData: Subscription = new Subscription();
    // Подписка на форму drivers
    public subscriptionDrivers: Subscription = new Subscription();
    public subscriptions: Subscription[] = [];
    // Подписка на форму owner
    public subscriptionOwner: Subscription = new Subscription();
    // Параметры контроллов
    public propertyControls: IPropertyControls = PropertyControls as unknown as IPropertyControls;
    // Форма фильтра
    public formFilter: UntypedFormGroup = this.fb.group({
        filterPrice: [null, Validators.required],
        franchise: ['Все предложения', Validators.required]
    });

    constructor(private readonly fb: UntypedFormBuilder) {
        this.form = this.fb.group({
            applicationId: [null, [Validators.required]],
            carCharacteristicsRequestId: [null],
            withoutLicenseNumber: [false],
            notMark: [false],
            policy: new UntypedFormControl(true, {
                updateOn: 'change',
                validators: Validators.requiredTrue
            }),
            license: [null, [
                Validators.required,
                Validators.pattern('[A-Za-zА-Яа-яЁё]{1}[0-9]{3}[A-Za-zА-Яа-яЁё]{2}[0-9]{2,3}')],
            ],
            withoutCarBrand: this.fb.group({
                carBrand: [null, [Validators.required]],
                carModel: [null, [Validators.required]],
                horsePower: [null, [Validators.required]],
                productionYear: [null, [Validators.required]],
                driverFirstName: [null, [Validators.required]],
                driverLastName: [null, [Validators.required]],
                driverMiddleName: [null],
                phone: [null],
            }),
            carData: this.fb.group({
                brandId: [null, [Validators.required]],
                modelId: [null, [Validators.required]],
                horsePower: [null, [Validators.required]],
                mileage: [null, [Validators.required]],
                price: [null, [Validators.required]],
                productionYear: [null, [Validators.required]],
                documentType: [CarDocumentTypesEnum.PTS, [Validators.required]],
                stsNumber: [null, [Validators.required]],
                stsDate: [null, [Validators.required]],
                ptsNumber: [null, [Validators.required]],
                ptsDate: [null, [Validators.required]],
                ePtsNumber: [null, [Validators.required]],
                ePtsDate: [null, [Validators.required]],
                identifier: [CarIdentificatorsEnum.VIN, [Validators.required]],
                vinNumber: [null, [Validators.required]],
                bodyNumber: [null, [Validators.required]],
                chassisNumber: [null, [Validators.required]],
                policyPurchasePlanningType: [null],
                ownedSince: [null],
                newTS: [false],
                name: [null],
                phone: [null],
            }),
            owner: this.fb.group({
                ownerFirstName: [null, [Validators.required]],
                ownerLastName: [null, [Validators.required]],
                ownerMiddleName: [null],
                ownerBirthDate: [null, [Validators.required]],
                passportLicense: [null, [Validators.required]],
                ownerPassportDate: [null, [Validators.required]],
                ownerCity: [null, [Validators.required]],
                ownerAddressDaData: [null],
                ownerIsJuridical: [false],
            }),
            ownerIsInsured: [true],
            unlimitedDrivers: [false],
            insurer: this.fb.group({
                insurerFirstName: [null, [Validators.required]],
                insurerLastName: [null, [Validators.required]],
                insurerMiddleName: [null],
                insurerBirthDate: [null, [Validators.required]],
                passportLicense: [null],
                insurerPassportDate: [null, [Validators.required]],
                insurerCity: [null, [Validators.required]],
                insurerAddressDaData: [null],
                insurerIsJuridical: [false],
            }),
            contacts: this.fb.group({
                email: [null, [Validators.required]],
                phone: [null, [Validators.required]],
                smsCode: [{value: null, disabled: true}, [Validators.required]],
            }),
            drivers: this.fb.array([]),
        });

        // this.validateAllFormFields(this.form);

        // По умолчанию добавляем одного водителя
        this.addDriver();

        // Подписка на изменение формы
        // Убираем т.к. не все контролы должны быть долступны, нужно убрать валидацию
        // this.subscribeForm();

        // Подписка на изменение формы carData
        this.subscribeFormCarData();

        // Подписка на изменение формы owner
        this.subscribeFormOwner();
    }

    public validateAllFormFields(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsUntouched({onlySelf: true});
                control.markAsPristine();
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    get drivers(): UntypedFormArray {
        return this.form.get('drivers') as UntypedFormArray;
    }

    // Новый водитель
    public newDriver(driver?: IDriver): UntypedFormGroup {
        return this.fb.group({
            driverFirstName: [driver?.driverFirstName, [Validators.required]],
            driverLastName: [driver?.driverLastName, [Validators.required]],
            oldDriverLastName: [driver?.oldDriverLastName, [Validators.required]],
            driverMiddleName: [driver?.driverMiddleName],
            driverBirthDate: [driver?.driverBirthDate, [Validators.required]],
            driverLicense: [driver?.driverLicense, [Validators.required]],
            oldLicense: [driver?.oldLicense, [Validators.required]],
            experienceDate: [driver?.experienceDate, [Validators.required]],
            experienceYear: [driver?.experienceYear, [Validators.required]],
            rememberOnlyYear: [driver?.rememberOnlyYear || false, [Validators.required]],
            licenseWasChanged: [driver?.licenseWasChanged || false, [Validators.required]],
            gender: [driver?.gender || 'Male', [Validators.required]],
            familyStatus: [driver?.familyStatus || 'Unmarried', [Validators.required]],
            children: [driver?.children ? driver?.children : 0, [Validators.required]],
        });
    }

    // Добавить нового водителя
    public addDriver(): void {
        this.drivers.push(this.newDriver());
    }

    // Удалить водителя
    public removeDriver(i: number): void {
        this.drivers.removeAt(i);
    }

    // Удалить водителя
    public resetDriver(i: number): void {
        const {
            driverFirstName,
            driverLastName,
            driverMiddleName,
            rememberOnlyYear,
            driverBirthDate,
            driverLicense,
            experienceDate,
            experienceYear,
            licenseWasChanged,
            oldLicense,
            oldDriverLastName,
            gender,
            familyStatus,
            children
        } = (this.drivers.controls[i] as UntypedFormGroup)?.controls;
        rememberOnlyYear.setValue(false);
        licenseWasChanged.setValue(false);
        driverFirstName.reset();
        driverLastName.reset();
        driverMiddleName.reset();
        oldDriverLastName.reset();
        driverBirthDate.reset();
        driverLicense.reset();
        oldLicense.reset();
        experienceDate.reset();
        experienceYear.reset();
        gender.setValue('Male');
        familyStatus.setValue('Unmarried');
        children.setValue(0);

        this.drivers.controls[i].markAsUntouched();
    }

    // Подписка на изменение формы
    private subscribeForm(): void {
        const {
            ownerIsInsured,
            insurer
        } = this.form?.controls;

        // Форма insurer доступна, только если ownerIsInsured = true
        // Cтрахователь является собственником авто
        this.subscriptions.push(subControl(ownerIsInsured, insurer, () => !ownerIsInsured.value));
    }

    // Подписка на изменения формы carData
    public subscribeFormCarData(): void {
        const {
            brandId,
            modelId,
            documentType,
            stsNumber,
            stsDate,
            ptsNumber,
            ptsDate,
            ePtsNumber,
            ePtsDate,
            identifier,
            vinNumber,
            bodyNumber,
            chassisNumber,
            productionYear,
            ownedSince,
            newTS
        } = (this.form.get('carData') as UntypedFormGroup)?.controls;

        // const isDocTypeSts = () => documentType.value === CarDocumentTypesEnum.STS;
        const isDocTypePts = () => documentType.value === CarDocumentTypesEnum.PTS;
        const isDocTypeEpts = () => documentType.value === CarDocumentTypesEnum.ePTS;

        const isIdentificatorVin = () => identifier.value === CarIdentificatorsEnum.VIN;
        const isDocTypeBody = () => identifier.value === CarIdentificatorsEnum.BodyNumber;
        const isDocTypeChassis = () => identifier.value === CarIdentificatorsEnum.ChassisNumber;

        this.subscriptions.push(subControl(brandId, modelId));

        this.subscriptions.push(subControl(documentType, ptsNumber, isDocTypePts));
        this.subscriptions.push(subControl(documentType, ePtsNumber, isDocTypeEpts));
        this.subscriptions.push(subControl(documentType, ptsDate, isDocTypePts));
        this.subscriptions.push(subControl(documentType, ePtsDate, isDocTypeEpts));

        this.subscriptions.push(subControl(identifier, vinNumber, isIdentificatorVin));
        this.subscriptions.push(subControl(identifier, bodyNumber, isDocTypeBody));
        this.subscriptions.push(subControl(identifier, chassisNumber, isDocTypeChassis));

        this.subscriptions.push(subControl(productionYear, ownedSince));
    }

    // Подписка на изменения формы driver
    public subscribeFormDriver(formDriver: UntypedFormGroup): void {
        const {
            rememberOnlyYear,
            experienceDate,
            experienceYear,
            licenseWasChanged,
            oldLicense,
            oldDriverLastName
        } = (formDriver as UntypedFormGroup)?.controls;

        this.subscriptions.push(subControl(rememberOnlyYear, experienceDate, () => !rememberOnlyYear.value));
        this.subscriptions.push(subControl(rememberOnlyYear, experienceYear, () => rememberOnlyYear.value));

        this.subscriptions.push(subControl(licenseWasChanged, oldLicense, () => licenseWasChanged.value));
        this.subscriptions.push(subControl(licenseWasChanged, oldDriverLastName, () => licenseWasChanged.value));
    }

    // Подписка на изменение формы owner
    private subscribeFormOwner(): void {

    }

    // Применяем данные авто, полученные из запроса обогащения
    public setCarCharacteristics(car: any): void {
        const {carData} = this.form.controls;
        const {
            brandId,
            modelId,
            horsePower,
            mileage,
            price,
            productionYear,
            documentType,
            stsNumber,
            stsDate,
            ptsNumber,
            ptsDate,
            ePtsNumber,
            ePtsidentifierDate,
            identifier,
            vinNumber,
            bodyNumber,
            chassisNumber,
            ownedSince
        } = (carData as UntypedFormGroup).controls;

        brandId.setValue(car.brandId);
        modelId.setValue(car.modelId);
        horsePower.setValue(car.carPower);
        productionYear.setValue(car.productionYear.toString());
        stsNumber.setValue(car.stsSeriesNumber);
        stsDate.setValue(car.stsIssueDate ? moment(car.stsIssueDate).format('DD.MM.YYYY') : '', {emitEvent: false});
        ptsNumber.setValue(car.ptsSeriesNumber);
        ptsDate.setValue(car.ptsIssueDate ? moment(car.ptsIssueDate).format('DD.MM.YYYY') : '', {emitEvent: false});
        vinNumber.setValue(car.vin);
    }

    // Сбрасываем форму
    public resetForm(): void {
        // Отписываемся от всех объектов за которыми следили
        this.subscriptions.forEach(s => s.unsubscribe());
        // СБрасываем всю форму
        this.form.reset();

        // Удаляем всех водителей
        this.drivers.clear();
        // Добавляем первого дефолтного водителя
        this.addDriver();

        // Сбрасываем общую форму и применяем дефолтные значения
        const {
            withoutLicenseNumber,
            policy,
            notMark
        } = this.form?.controls;
        withoutLicenseNumber.setValue(false);
        policy.setValue(true);
        notMark.setValue(false);

        // Сбрасываем форму данных авто и применяем дефолтные значения
        const {
            documentType,
            identifier
        } = (this.form.get('carData') as UntypedFormGroup)?.controls;
        documentType.setValue(CarDocumentTypesEnum.PTS);
        identifier.setValue(CarIdentificatorsEnum.VIN);

        // Сбрасываем форму контактов и применяем дефолтные значения
        const {
            smsCode
        } = (this.form.get('contacts') as UntypedFormGroup)?.controls;
        smsCode.setValue('');

        // Сбрасываем собственника и применяем дефолтные значения
        this.form.get('ownerIsInsured')?.setValue(true);

        this.subscribeFormCarData();
        this.form.markAsUntouched();
    }

}

<div class="auto-info">
    <app-breadcrumb></app-breadcrumb>

    <div [hidden]="formService.form.get('notMark')?.value">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h2>Данные авто</h2>
            <button
                *ngIf="formService.form.get('license')?.value && !formService.form.get('withoutLicenseNumber')?.value"
                routerLink="/"
                queryParamsHandling="merge"
                type="button"
                [popover]="'Редактировать номер авто'"
                triggers="mouseenter:mouseleave"
                class="btn btn-link btn-license-number text-end text-black text-decoration-none p-0">
                <div *ngIf="formService.form.get('license')?.value">{{ formService.form.get('license')?.value }}</div>
                <div>Изменить гос. номер</div>
            </button>
            <button *ngIf="formService.form.get('withoutLicenseNumber')?.value"
                    routerLink="/"
                    queryParamsHandling="merge"
                    type="button"
                    [popover]="'Редактировать номер авто'"
                    triggers="mouseenter:mouseleave"
                    class="btn btn-link btn-license-number text-end text-black text-decoration-none p-0">
                <div>Изменить гос. номер</div>
            </button>
        </div>
    </div>

    <div class="alert alert-secondary alert-dismissible border-0 mb-3" role="alert"
         [hidden]="formService.form.get('notMark')?.value">
        <div> Заполните информацию ниже, она формирует стоимость КАСКО для вашего автомобиля</div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>

    <div *ngIf="getAlertModelId(form.get('modelId')?.value, kaskoService.modelsList)" class="alert alert-warning border-light"
         [innerHTML]="getAlertModelId(form.get('modelId')?.value, kaskoService.modelsList)"></div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate [hidden]="formService.form.get('notMark')?.value">
        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <app-autocomplete
                    formControlName="brandId"
                    [typeaheadList]="kaskoService.brandsList"
                    [typeaheadOptionsLimit]="800"
                    [isReadonly]="isLoading"
                    (typeaheadOnSelect)="selectBrand($event)"></app-autocomplete>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <app-autocomplete
                    formControlName="modelId"
                    [typeaheadList]="kaskoService.modelsList"
                    [typeaheadOptionsLimit]="500"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
        </div>

        <div class="row d-flex align-items-center" *ngIf="!formService.form.get('notMark')?.value">
            <div class="col mb-3">
                <div class="form-check form-switch form-check-reverse">
                    <input class="form-check-input" type="checkbox" id="notMark" [formControl]="notMark">
                    <label class="form-check-label" for="notMark">Нет моей марки или модели</label>
                </div>
            </div>
            <div class="col-auto mb-3 text-center fs-4">
                <div class="d-flex"
                     [popover]="'В случае отсутствия вашей марки или модели в списке, переключите тумблер'"
                     triggers="mouseenter:mouseleave">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         class="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="row" [formGroup]="form" [hidden]="formService.form.get('notMark')?.value">
            <div class="col-12 col-md-6 mb-3">
                <app-form-number-field formControlName="horsePower" [isReadonly]="isLoading"></app-form-number-field>
            </div>
            <div class="col-12 col-md-6 mb-3">
                <app-autocomplete
                    formControlName="productionYear"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.productionYearList"
                    [typeaheadOptionsLimit]="kaskoService.productionYearList.length > 7 ? 7 : kaskoService.productionYearList.length"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb-3">
                <app-form-text-field formControlName="stsNumber" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb-3">
                <app-form-date-field formControlName="stsDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-autocomplete
                    formControlName="documentType"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.documentTypes"
                    [typeaheadOptionsLimit]="kaskoService.documentTypes.length"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-lg-6 mb-3">
                <app-form-text-field [hidden]="form.get('documentType')?.value !== carDocumentTypes.PTS"
                                     formControlName="ptsNumber" [isReadonly]="isLoading"></app-form-text-field>
                <app-form-text-field [hidden]="form.get('documentType')?.value !== carDocumentTypes.ePTS"
                                     formControlName="ePtsNumber" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-form-date-field [hidden]="form.get('documentType')?.value !== carDocumentTypes.PTS"
                                     formControlName="ptsDate" [isReadonly]="isLoading"></app-form-date-field>
                <app-form-date-field [hidden]="form.get('documentType')?.value !== carDocumentTypes.ePTS"
                                     formControlName="ePtsDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-autocomplete
                    formControlName="identifier"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.carIdentificators"
                    [typeaheadOptionsLimit]="kaskoService.carIdentificators.length"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 col-md-8 col-lg-9 mb-3">
                <app-form-text-field [hidden]="form.get('identifier')?.value !== carIdentificators.VIN"
                                     formControlName="vinNumber" [isReadonly]="isLoading"></app-form-text-field>
                <app-form-text-field [hidden]="form.get('identifier')?.value !== carIdentificators.BodyNumber"
                                     formControlName="bodyNumber" [isReadonly]="isLoading"></app-form-text-field>
                <app-form-text-field [hidden]="form.get('identifier')?.value !== carIdentificators.ChassisNumber"
                                     formControlName="chassisNumber" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <div class="row">
                    <div class="col">
                        <app-form-number-field formControlName="mileage"
                                               [isReadonly]="isLoading"></app-form-number-field>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <app-autocomplete
                    formControlName="ownedSince"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.ownedSinceList"
                    [typeaheadOptionsLimit]="kaskoService.ownedSinceList.length >= 7 ? 7 : kaskoService.ownedSinceList.length"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <app-form-number-field formControlName="price" [isReadonly]="isLoading"></app-form-number-field>
            </div>
            <div *ngIf="environment.partnerSystemName !== 'alfa'" class="col-12 col-md-4 mb-3">
                <app-autocomplete
                    formControlName="policyPurchasePlanningType"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.policyPurchasePlanningTypeList"
                    [typeaheadOptionsLimit]="4"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 mb-3">
                <div class="form-check form-switch form-check-reverse">
                    <input class="form-check-input" type="checkbox" id="newTS" formControlName="newTS">
                    <label class="form-check-label" for="newTS">Новое транспортное средство</label>
                </div>
            </div>
        </div>
        <div class="row actions">
            <div class="col-12 col-sm-6 col-md-4">
                <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isLoading">Продолжить</span>
                </button>
            </div>
        </div>
    </form>

    <div [hidden]="!formService.form.get('notMark')?.value">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h2>Для добавления марки или моделии оставьте заявку</h2>
        </div>
    </div>

    <div class="row d-flex align-items-center" *ngIf="formService.form.get('notMark')?.value">
        <div class="col mb-3">
            <div class="form-check form-switch form-check-reverse">
                <input class="form-check-input" type="checkbox" id="notMarkId" [formControl]="notMark">
                <label class="form-check-label" for="notMarkId">Нет моей марки или модели</label>
            </div>
        </div>
        <div class="col-auto mb-3 text-center fs-4">
            <div class="d-flex"
                 [popover]="'В случае отсутствия вашей марки или модели в списке, переключите тумблер'"
                 triggers="mouseenter:mouseleave">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                </svg>
            </div>
        </div>
    </div>

    <form [formGroup]="withoutCarBrandForm"
          (ngSubmit)="onSubmitNotMark()"
          novalidate
          [hidden]="!formService.form.get('notMark')?.value">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3 mb-3">
                <app-form-text-field
                    formControlName="carBrand"
                    [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
                <app-form-text-field
                    formControlName="carModel"
                    [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
                <app-form-number-field formControlName="horsePower" [isReadonly]="isLoading"></app-form-number-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
                <app-autocomplete
                    formControlName="productionYear"
                    [isReadonly]="isLoading"
                    [typeaheadList]="kaskoService.productionYearList"
                    [typeaheadOptionsLimit]="kaskoService.productionYearList.length > 7 ? 7 : kaskoService.productionYearList.length"
                    [isDropdown]="true"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="driverLastName"
                    [suggestionPart]="daDataSystemName.SURNAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="driverFirstName"
                    [suggestionPart]="daDataSystemName.NAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="driverMiddleName"
                    [suggestionPart]="daDataSystemName.PATRONYMIC"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
            </div>
        </div>
        <div class="row actions">
            <div class="col-12 col-sm-6 col-md-4">
                <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isLoading">Продолжить</span>
                </button>
            </div>
        </div>
    </form>

</div>
<!--<pre>{{ formService.form?.value | json }}</pre>-->

import { IDocumentTypes } from '../interface/document-types.interface';
import { CarDocumentTypesEnum } from '../enums/car-document-types.enum';

// Список всех типов документа
export const DOCUMENT_TYPES: IDocumentTypes[] = [
    {
        systemName: CarDocumentTypesEnum.PTS,
        value: 'ПТС'
    },
    {
        systemName: CarDocumentTypesEnum.ePTS,
        value: 'еПТС'
    }
];

<div class="card mb-4 h-auto shadow-sm rounded-4 offer" *ngIf="offer">
    <div class="card-body">
           <div class="row">
               <div *ngFor="let badge of offer.badges"
                    [style]="badge.style"
                    class="col-auto badge rounded-pill">
                   {{badge.title}}
               </div>
           </div>

        <div class="row align-items-center">
            <div class="col-12 col-sm-12 col-md-3 col-lg-4 mb-2 mb-sm-0">
                <div class="m-0 d-flex align-items-center">
                    <img src="./assets/images/partner-logo/{{offer.insurerType}}-logo.svg"
                         class="rounded d-inline-block" alt="{{offer.insurerName}}">
                    <div>
                        <div class="h6 m-0 p-2 pt-0 pb-0"><strong>{{ offer.insurerName }}</strong></div>
                        <div class="m-0 p-2 pt-0 pb-0 text-secondary">
                            <div class="rounded-2 p-2" [class.gray-card]="isShowMiniKasko
                             && offer.productId === 'e8c975a7-74ba-4783-8cc1-2755514cc73f'
                              || offer.productId === '2b32991b-578e-46cc-81ff-da10d3a8226f'
                              || offer.productId === 'f1703509-bdba-4c5d-b7d0-ff4b7e54b6c4'">
                                <span [class.fw-bold]="isShowMiniKasko
                             && offer.productId === 'e8c975a7-74ba-4783-8cc1-2755514cc73f'
                              || offer.productId === '2b32991b-578e-46cc-81ff-da10d3a8226f'
                              || offer.productId === 'f1703509-bdba-4c5d-b7d0-ff4b7e54b6c4'">{{ offer.productName }}</span>
                                <div class="offer-product-description" *ngIf="isShowMiniKasko
                             && offer.productId === 'e8c975a7-74ba-4783-8cc1-2755514cc73f'
                              || offer.productId === '2b32991b-578e-46cc-81ff-da10d3a8226f'
                              || offer.productId === 'f1703509-bdba-4c5d-b7d0-ff4b7e54b6c4'">
                                    <div *ngFor="let item of offer.productDescriptions">
                                        {{ item.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-7 col-md-4 col-lg-4">
                <div class="row">
                    <div class="col">
                        <div class="text-secondary">Стоимость</div>
                        <div class="fw-bold">
                            <input type="text"
                                   appIsNumeric
                                   postfix="₽"
                                   inputmode="numeric"
                                   numericThousandSeparator=" "
                                   readonly
                                   class="form-control-plaintext fw-bold p-0"
                                   id="preapprovePremiumAmount"
                                   [value]="offer.isOffline ? offer?.preapprovePremiumAmount?.toFixed(0) : offer?.premiumAmount?.toFixed(0)">
                        </div>
                    </div>
                    <div class="col">
                        <div class="text-secondary">Франшиза</div>
                        <div *ngIf="offer.offerFranchisePreapprove">
                            <input type="text"
                                   appIsNumeric
                                   postfix="₽"
                                   inputmode="numeric"
                                   numericThousandSeparator=" "
                                   readonly
                                   class="form-control-plaintext p-0"
                                   id="offerFranchisePreapprove"
                                   [value]="offer.offerFranchisePreapprove?.toFixed(0)">
                        </div>
                        <div *ngIf="!offer.offerFranchisePreapprove">
                            -
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-5 col-md-5 col-lg-4 text-start text-sm-end mt-2 mt-sm-0">
                <div class="row d-flex justify-content-between justify-content-sm-end">
                    <div *ngIf="offer.promocodes.length > 0" class="col-auto">
                        <button type="button" class="btn btn btn-lg border-0 btn-icon-gray"
                                (click)="isShowPromocodes = !isShowPromocodes">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2689_6543)">
                                    <path d="M20 7H18.262C18.8274 6.5016 19.2762 5.88488 19.5766 5.1936C19.8769 4.50232 20.0215 3.75341 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2C18.7348 2 18.4804 2.10536 18.2929 2.29289C18.1054 2.48043 18 2.73478 18 3C18 5.622 15.629 6.53 13.826 6.841C14.4915 5.66105 14.8921 4.35037 15 3C15 2.20435 14.6839 1.44129 14.1213 0.87868C13.5587 0.31607 12.7956 0 12 0C11.2044 0 10.4413 0.31607 9.87868 0.87868C9.31607 1.44129 9 2.20435 9 3C9.10791 4.35037 9.50852 5.66105 10.174 6.841C8.371 6.53 6 5.622 6 3C6 2.73478 5.89464 2.48043 5.70711 2.29289C5.51957 2.10536 5.26522 2 5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3C3.97851 3.75341 4.12307 4.50232 4.42344 5.1936C4.7238 5.88488 5.1726 6.5016 5.738 7H4C2.93913 7 1.92172 7.42143 1.17157 8.17157C0.421427 8.92172 0 9.93913 0 11L0 12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14V19C2.00159 20.3256 2.52888 21.5964 3.46622 22.5338C4.40356 23.4711 5.67441 23.9984 7 24H17C18.3256 23.9984 19.5964 23.4711 20.5338 22.5338C21.4711 21.5964 21.9984 20.3256 22 19V14C22.5304 14 23.0391 13.7893 23.4142 13.4142C23.7893 13.0391 24 12.5304 24 12V11C24 9.93913 23.5786 8.92172 22.8284 8.17157C22.0783 7.42143 21.0609 7 20 7ZM12 2C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3C12.8793 4.06283 12.5387 5.08891 12 6.013C11.4613 5.08891 11.1207 4.06283 11 3C11 2.73478 11.1054 2.48043 11.2929 2.29289C11.4804 2.10536 11.7348 2 12 2ZM2 11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H11V12H2V11ZM4 19V14H11V22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19ZM20 19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7956 22 17 22H13V14H20V19ZM13 12V9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V12H13Z" fill="#98A2B3"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2689_6543">
                                        <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </button>
                    </div>
                    <div *ngIf="offer.productDescriptions.length > 0" class="col-auto">
                        <button type="button" class="btn btn btn-lg border-0 btn-icon-gray"
                                (click)="isShowDescription = !isShowDescription">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22ZM12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8ZM13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18L13 11Z"
                                      fill="#98A2B3"/>
                            </svg>
                        </button>
                    </div>
                    <div class="col col-lg-auto">
                        <button type="button" (click)="checkOrder(offer)" [disabled]="isLoading"
                                class="btn btn-primary btn-lg w-100 select-offer-btn">
                            <span *ngIf="!isLoading && offer.isOffline">
                                Выбрать
                            </span>
                            <span *ngIf="!isLoading && !offer.isOffline">
                                Оформить
                            </span>
                            <span *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isShowDescription" class="description-list">
            <div class="row description-item mt-3" *ngFor="let item of offer.productDescriptions">
                <div class="col-auto">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.90293 17L20 7.51871L18.5683 6L9.91876 14.1317L6.44838 10.8244L5 12.3274L9.90293 17Z"
                              fill="#0B1F35"/>
                    </svg>
                </div>
                <div class="col">
                    <div class="description-title">
                        {{ item.title }}
                    </div>
                    <div class="description-text">
                        {{ item.text }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isShowPromocodes" class="description-list">
            <div class="row description-item mt-3">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0" *ngFor="let item of offer.promocodes">
                    <div class="gift" [style.background-color]="item.color">
                        <div class="row d-flex flex-column justify-content-between">
                            <div class="col">
                                <img [src]="item.imageSrc" alt="">
                            </div>
                            <div class="col-auto">
                                <div class="gift-title">
                                    {{item.promocodeTitle}}
                                </div>
                                <div class="gift-sub">
                                    {{item.promocodeSubtitle}}
                                </div>
                                <div class="gift-desc">
                                    {{item.promocodeDescription}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-ptimary gift-alert border-0 mb-0 mt-0 mt-sm-3" role="alert">
                <div>Промокоды с подарками будут отправлены вам на почту после оформления полиса</div>
            </div>
        </div>
    </div>
</div>

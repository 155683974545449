import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {delay, expand, map, switchMap, take, takeWhile, tap} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import moment from 'moment-mini';
import { HttpService } from './http.service';

import ChildrenList from '../../../assets/data/children-list.json';

// Сервисы
import { FormService } from './form.service';

// Перечисления
import { CarDocumentTypesEnum } from '../enums/car-document-types.enum';
import { DOCUMENT_TYPES } from '../constants/document-types';
import { CAR_IDENTIFICATORS } from '../constants/car-identificators';

// Хелперы
import { TextMaskHelper } from '../helpers/textmaskHelper';

// Интерфейсы
import { ICarBrand } from '../interface/car-brands.interface';
import { ICarModel } from '../interface/car-models.interface';
import { IProductionYear } from '../interface/production-year.interface';
import { IChildrenInterface } from '../interface/children.interface';
import { IDocumentTypes } from '../interface/document-types.interface';
import { ICarIdentificators } from '../interface/car-identificators.interface';
import { ICarData } from '../interface/application.interface';
import { IDriver } from '../interface/driver.interface';
import { IApplicationRequest } from '../interface/application-request.interface';
import { YandexMetrikaService } from './yandex-metrika.service';
import { SettingsService } from '../../core/services/settings.service';
import { IFranchise } from '../interface/franchise.interface';
import { IPolicyPurchasePlanningType } from '../interface/policy-purchase-planning-type.interface';
import { APP_STATUS } from '../enums/app-status.enum';
import {IOwnedSince} from '../interface/owned-since.interface';
import {IOffer} from '../interface/offers.interface';
import {FormGroup, Validators} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class KaskoService extends HttpService {
    // Статус заявки - WidgetStatus
    public isWidgetStatus = false;
    // Статус анкеты из запроса GetAppInfo
    public appStatus = '';
    // Статус верификации номера телефона
    public phoneStatus = false;
    // Полес оплачен
    public isPaid = false;
    // Массив данных авто брендов
    public brandsList: ICarBrand[] = [];
    // Массив данных авто брендов
    public modelsList: ICarModel[] = [];
    // Массив доступных для выбора годов выпуска авто
    public productionYearList: IProductionYear[] = [];
    // Массив доступных для выбора значений "Когда нужен полис"
    public policyPurchasePlanningTypeList: IPolicyPurchasePlanningType[] = [];
    // Данные списка о количестве детей
    public childrenList: IChildrenInterface[] = ChildrenList;
    // Данные списка типов документа
    public documentTypes: IDocumentTypes[] = DOCUMENT_TYPES;
    // Данные списка типов документа
    public carIdentificators: ICarIdentificators[] = CAR_IDENTIFICATORS;
    // Массив доступных для выбора годов "В собственности с какого года
    public ownedSinceList: IOwnedSince[] = [];
    // Список офферов
    public offers: any = [];
    // Весь список офферов без фильтрации
    public allOffers: any = [];
    // Ссылка на оплату
    public paymentLink!: string;
    // Выбранный оффер
    public selectedOffer!: IOffer | null;
    // Показываем данные формы для редактирования
    public isShowEditInfo = false;

    // Список доступных для выбора франшиз
    public franchiseList!: IFranchise[];

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        protected readonly ym: YandexMetrikaService,
        private readonly settingsService: SettingsService,
        private readonly formService: FormService
    ) {
        super(http, ym);
        this.getProductionYearList();
        this.getPolicyPurchasePlanningTypeList();
        this.getFranchiseList();
    }

    // Получаем данные всех брендов авто
    public getCarBrands(): Observable<ICarBrand[]> {
        return this.post(environment.apiUrl + 'dictionaries/GetAllBrands', {
            apiKey: this.settingsService.apiKey,
        }).pipe(
            take(1),
            map((data: any) => {
                this.brandsList = data.value.brands;
                return (data && data.value && data.value.brands) || [];
            })
        );
    }

    // Получаем название бренда авто по ID
    public getCarBrandNameById = (brandId?: number) =>
        this.brandsList
            .filter((brands) =>
                brandId
                    ? brands.brandId === brandId
                    : brands.brandId ===
                      this.formService.form.get('carData')?.value?.brandId
            )
            .map((brand) => brand.brandName)

    // Получаем данные всех моделей, выбранной марки авто
    public getCarModels(brandId?: number | string): Observable<ICarModel[]> {
        if (!brandId) {
            brandId = this.formService.form.get('brandId')?.value;
        }

        if (!brandId && !this.formService.form.get('brandId')?.value) {
            return of([]);
        } else {
            return this.post(environment.apiUrl + 'dictionaries/GetAllModels', {
                apiKey: this.settingsService.apiKey,
                brandId,
            }).pipe(
                map((data: any) => {
                    this.modelsList = data.value?.models;
                    return (data && data.value && data.value.models) || [];
                })
            );
        }
    }

    // Получить информацию о текущей анкете
    public getAppInfo(): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
        };
        return this.post(
            environment.apiUrl + 'app/GetApplicationInfo',
            request
        ).pipe(
            tap((response: any) => {
                if (response.result) {
                    this.appStatus =
                        response.result &&
                        response?.value?.applicationData.applicationStatus;
                    this.isPaid =
                        response.result &&
                        response?.value?.applicationData.isPaid;
                }
            })
        );
    }

    // Получаем название бренда авто по ID
    public getCarModelNameByModelId = (modelId?: number) =>
        this.modelsList
            .filter((models) =>
                modelId
                    ? models.modelId === modelId
                    : models.modelId ===
                      this.formService.form.get('carData')?.value?.modelId
            )
            .map((model) => model.modelName)

    // Получаем список доступных годов выпуска авто
    public getProductionYearList(): IProductionYear[] {
        const currentYear = new Date().getFullYear();
        for (let i = currentYear - 10; i <= currentYear; ) {
            this.productionYearList.push({ id: i, value: i.toString() });
            i++;
        }
        return this.productionYearList;
    }

    // Получаем список доступных годов "В собственности с какого года"
    public getOwnedSinceList(productionYear?: string): IOwnedSince[] {
        this.ownedSinceList = [];
        const productionYearValue = this.formService.form.get('carData')?.value?.productionYear;
        if (!productionYear && !productionYearValue) {
            productionYear = this.productionYearList[this.productionYearList.length - 1]?.value;
        } else if (!productionYear && productionYearValue) {
            productionYear = productionYearValue;
        } else if (!productionYear) {
            productionYear = new Date().getFullYear().toString();
        }
        const currentYear = new Date().getFullYear();
        for (let i = Number(productionYear); i <= currentYear; ) {
            this.ownedSinceList.push({ id: i, value: i.toString() });
            i++;
        }
        return this.ownedSinceList;
    }

    // Получаем список доступных годов выпуска авто
    public getPolicyPurchasePlanningTypeList(): IPolicyPurchasePlanningType[] {
        const currentList = [
            'Не знаю, хочу прицениться',
            'В ближайшие 1-3 дня',
            'В этом месяце',
            'Через месяц',
        ];
        for (let i = 0; i < currentList.length; i++) {
            this.policyPurchasePlanningTypeList.push({
                id: i,
                value: currentList[i],
            });
        }
        return this.policyPurchasePlanningTypeList;
    }

    // Получаем список доступных для выбора франшиз
    public getFranchiseList(): IFranchise[] {
        const franchiseFilterList = [
            {
                id: 0,
                value: 'Все предложения',
            },
            {
                id: 1,
                value: 'Без франшизы',
            },
        ];

        this.allOffers.forEach((offer: any) => {
            if (
                offer.offerFranchisePreapprove >= 5000 &&
                offer.offerFranchisePreapprove <= 50000
            ) {
                const findFilter = franchiseFilterList.find(
                    (item) => item.id === 2
                );
                if (!findFilter) {
                    franchiseFilterList.push({
                        id: 2,
                        value: 'От 5 000 до 50 000',
                    });
                }
            } else if (offer.offerFranchisePreapprove >= 50001) {
                const findFilter = franchiseFilterList.find(
                    (item) => item.id === 3
                );
                if (!findFilter) {
                    franchiseFilterList.push({
                        id: 3,
                        value: 'От 50 001 и выше',
                    });
                }
            }
        });

        return franchiseFilterList;
    }

    public sendKaskoApplication(): Observable<any> {
        this.isWidgetStatus = false;
        return this.post(
            environment.apiUrl + 'app/SendKaskoApplication',
            this.getApplicationSendRequest()
        );
    }

    public saveClientNotMarkForm(): Observable<any> {
        this.isWidgetStatus = false;
        return this.post(
            environment.apiUrl + 'app/SaveClientCarData',
            this.getClientCarData()
        );
    }

    // Отправляем смс
    public sendVerificationCode(fromOsago: boolean = false): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            clientName:
                value?.owner?.ownerFirstName +
                ' ' +
                value?.owner?.ownerLastName,
            clientPhone: '7' + value?.contacts?.phone,
            fromOsago,
        };

        return this.post(
            environment.apiUrl + 'app/SendVerificationCode',
            request
        ).pipe(
            tap((response: any) => {
                if (response.result) {
                    this.phoneStatus =
                        response.result && response?.value?.confirmed;
                }
            })
        );
    }

    // Отправляем смс
    public authorize(): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            clientPhone: '7' + value?.contacts?.phone,
        };

        return this.post(environment.apiUrl + 'app/Authorize', request).pipe(
            tap((response: any) => {
                if (response.result) {
                    this.phoneStatus =
                        response.result && response?.value?.authorized;
                }
            })
        );
    }

    // Верификация смс кода
    public verifyCode(smsCode: string): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            smsCode,
        };

        return this.post(environment.apiUrl + 'app/VerifyCode', request);
    }

    // Отправляем данные в страховые компании - sendToInsuence и получаем офферов
    public sendToInsurersGetOffers(): Observable<any> {
        return this.post(
            environment.apiUrl + 'app/SendToInsurers',
            this.getApplicationSendRequest()
        ).pipe(expand((data) => this.getOffers()));
    }

    // Отправляем данные в страховые компании - sendToInsuence
    public sendToInsurers(): Observable<any> {
        return this.post(
            environment.apiUrl + 'app/SendToInsurers',
            this.getApplicationSendRequest()
        );
    }

    public getOffers(): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
        };

        return this.post(environment.apiUrl + 'app/GetOffers', request).pipe(
            delay(2000),
            takeWhile((res: any) => {
                if (res.value.offers && res.value.offers.length) {
                    // Фильтруем только те офферы, которых нет в массиве this.offers
                    const newOffers = res.value.offers.filter((offer: any) =>
                        !this.offers.some((existingOffer: any) => existingOffer.offerId === offer.offerId));

                    // Добавляем отфильтрованные офферы
                    this.offers = [...this.offers, ...newOffers];

                    // Аналогично для allOffers, если нужно
                    this.allOffers = [...this.allOffers, ...newOffers];
                }
                return !res?.value?.allOffersReceived;
            })
        );
    }

    // Выбрали оффера
    public selectOffer(offer: any): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            offerId: offer?.offerId,
        };

        return this.post(environment.apiUrl + 'app/SelectOffer', request);
    }

    // Получаем ссылку на оплату
    public getPaymentLinkRepeatedly(): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            offerId: this.selectedOffer?.offerId
        };

        // Делаем запрос с интервалом 2 секунды до тех пор пока не получим ссылку на оплату
        return timer(0, 2000).pipe(
            switchMap(() => this.post(environment.apiUrl + 'app/GetKaskoPaymentLink', request)),
            takeWhile((res) => res.result && res.value?.status !== 'Received', true)
        );
    }

    public updateOffersList(): Observable<any> {
        const {value} = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId
        };

        return timer(0, 2000).pipe(
            switchMap(() => this.post(environment.apiUrl + 'app/GetOffers', request)),
            takeWhile((res: any) => {
                // Создаем новый Set из существующих офферов
                const offersSet = new Set(this.offers.map((offer: IOffer) => offer.offerId));

                // Фильтруем только уникальные офферы
                const uniqueOffers = res.value.offers.filter((offer: IOffer) => !offersSet.has(offer.offerId));

                // Добавляем уникальные офферы к текущему списку
                this.offers = [...this.offers, ...uniqueOffers];

                return !res?.value?.allOffersReceived;
            })
        );
    }

    // Запрос на получение данных авто по номеру
    public createCarCharacteristicsReportObservable(
        license: string
    ): Observable<any> {
        const { value } = this.formService.form;
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: value?.applicationId,
            licensePlate: license,
        };

        return this.post('app/CreateCarCharacteristicsReport', request);
    }

    // Собираем данные каско для отправки
    private getApplicationSendRequest(): IApplicationRequest {
        const { value } = this.formService.form;
        console.log(value?.carData);
        return {
            ApiKey: this.settingsService.apiKey,
            ApplicationId: value?.applicationId,
            PolicyPurchasePlanningType:
                value?.carData.policyPurchasePlanningType,
            CarCharacteristicsRequestId: value?.carCharacteristicsRequestId
                ? value?.carCharacteristicsRequestId
                : undefined,
            CarData: {
                CarDocument: value?.carData?.documentType
                    ? this.getCarDocuments(value?.carData)
                    : undefined,
                CarDocument2: this.getCarDocuments2(value?.carData),
                CarIdentificators: {
                    Vin: value?.carData?.vinNumber
                        ? value?.carData?.vinNumber
                        : undefined,
                    ChassisNumber: value?.carData?.chassisNumber
                        ? value?.carData?.chassisNumber
                        : undefined,
                    BodyNumber: value?.carData?.bodyNumber
                        ? value?.carData?.bodyNumber
                        : undefined,
                },
                CurrentPrice: value?.carData?.price,
                EnginePower: value?.carData?.horsePower,
                Mileage: value?.carData?.mileage,
                ModelId: value?.carData?.modelId,
                ProductionYear: value?.carData?.productionYear,
                LicensePlate: value?.license,
                OwnedSince: value?.carData?.ownedSince ? Number(value?.carData?.ownedSince) : undefined,
                NewTS: value?.carData?.newTS,
            },
            HasDriversRestriction: !value.unlimitedDrivers,
            DriversData: !value.unlimitedDrivers
                ? value?.drivers?.map((driver: IDriver) => {
                      // Дата начала стажа
                      const experienceStartDate = driver.rememberOnlyYear
                          ? `31.12.${driver.experienceYear}`
                          : TextMaskHelper.getDate(driver.experienceDate) || '';
                      return {
                          FirstName: driver.driverFirstName,
                          LastName: driver.driverLastName,
                          MiddleName: driver.driverMiddleName,
                          ChildCount: driver.children,
                          BirthDate: this.getFormatDate(driver.driverBirthDate),
                          DriverExperience: moment().diff(
                              moment(experienceStartDate, 'DD.MM.YYYY', false),
                              'years'
                          ),
                          Gender: driver.gender,
                          DriverAge: moment().diff(
                              moment(
                                  this.getFormatDate(driver.driverBirthDate),
                                  'DD.MM.YYYY',
                                  false
                              ),
                              'years'
                          ),
                          MaritalStatus: driver.familyStatus,
                          driverLicense: {
                              isForeignLicense: false,
                              licenseIssueDate:
                                  TextMaskHelper.getDate(
                                      driver.experienceDate
                                  ) || `31.12.${driver.experienceYear}`,
                              licenseNumber: TextMaskHelper.removeMask(
                                  driver.driverLicense || '',
                                  10
                              ).substr(4),
                              licenseSeries: TextMaskHelper.removeMask(
                                  driver.driverLicense || '',
                                  10
                              )
                                  .substr(0, 4)
                                  .toUpperCase(),
                          },
                          experienceStartDate,
                      };
                  })
                : [],
            Owner: {
                OwnerIsInsured: value.ownerIsInsured,
                FirstName: value?.owner?.ownerFirstName,
                LastName: value?.owner?.ownerLastName,
                MiddleName: value?.owner?.ownerMiddleName,
                BirthDate: value?.owner?.ownerBirthDate
                    ? this.getFormatDate(value?.owner?.ownerBirthDate)
                    : '',
                Passport: this.getPassport(value?.owner, true),
                Phone: '7' + value?.contacts?.phone,
                Email: value?.contacts?.email,
                IsJuridical: value?.owner?.ownerIsJuridical ? 1 : 0,
            },
            Insured: !value.ownerIsInsured
                ? {
                      FirstName: value?.insurer?.insurerFirstName,
                      LastName: value?.insurer?.insurerLastName,
                      MiddleName: value?.insurer?.insurerMiddleName,
                      BirthDate: this.getFormatDate(
                          value?.insurer?.insurerBirthDate
                      ),
                      Passport: this.getPassport(value?.insurer, false),
                      Phone: '7' + value?.contacts?.phone,
                      Email: value?.contacts?.email,
                      IsJuridical: value?.insurer?.insurerIsJuridical ? 1 : 0,
                  }
                : undefined,
        };
    }

    // Собираем данные каско для отправки
    private getClientCarData(): any {
        const applicationId = this.formService.form.get('applicationId')?.value;
        const { value } = this.formService.form.get('withoutCarBrand') as FormGroup;
        return {
            ApiKey: this.settingsService.apiKey,
            ApplicationId: applicationId,
            FirstName: value.driverFirstName,
            LastName: value.driverLastName,
            MiddleName: value.driverMiddleName,
            Phone: '7' + value.phone,
            CarBrand: value.carBrand,
            CarModel: value.carModel,
            CarPower: value.horsePower,
            ProductionYear: value.productionYear
        };
    }

    // Получение данных документа авто
    private getCarDocuments(carData: ICarData): any {
        switch (carData?.documentType) {
            case CarDocumentTypesEnum.PTS:
                return carData?.ptsNumber && carData?.ptsDate
                    ? {
                          DocumentType: carData?.documentType,
                          DocumentSeries: carData?.ptsNumber
                              ?.toString()
                              .slice(0, 4),
                          DocumentNumber: carData?.ptsNumber
                              ?.toString()
                              .slice(4, 10),
                          DocumentIssueDate: this.getFormatDate(
                              carData?.ptsDate
                          )
                      }
                    : undefined;
            case CarDocumentTypesEnum.ePTS:
                return carData?.ePtsNumber && carData?.ePtsDate
                    ? {
                          DocumentType: carData?.documentType,
                          DocumentSeries: carData?.ePtsNumber
                              ?.toString()
                              .slice(0, 4),
                          DocumentNumber: carData?.ePtsNumber
                              ?.toString()
                              .slice(4, 10),
                          DocumentIssueDate: carData?.ePtsDate
                              ? this.getFormatDate(carData?.ePtsDate)
                              : undefined
                      }
                    : undefined;
            default:
                return undefined;
        }
    }

    // Получение данных документа авто
// Получение данных документа авто
    private getCarDocuments2(carData: ICarData): any {
        return carData?.stsNumber && carData?.stsDate
            ? {
                DocumentType: 'STS',
                DocumentSeries: carData?.stsNumber
                    ?.toString()
                    .slice(0, 4),
                DocumentNumber: carData?.stsNumber
                    ?.toString()
                    .slice(4, 10),
                DocumentIssueDate: this.getFormatDate(
                    carData?.stsDate
                )
            }
            : undefined;
    }

    // Приводит дату в нужный формат дд.мм.гггг
    private getFormatDate(date: string): string {
        const replaceDate = date?.split('.').join('');
        if (replaceDate) {
            return `${replaceDate?.slice(0, 2)}.${replaceDate?.slice(
                2,
                4
            )}.${replaceDate?.slice(4, 9)}`;
        } else {
            return date;
        }
    }

    // Получаем данные паспорта
    private getPassport(ownerOrInsurer: any, isOwner: boolean = true): any {
        return {
            PassportSeries: ownerOrInsurer.passportLicense
                ? ownerOrInsurer.passportLicense?.toString().slice(0, 4)
                : '1234',
            PassportNumber: ownerOrInsurer.passportLicense
                ? ownerOrInsurer.passportLicense?.toString().slice(4, 10)
                : '567890',
            PassportIssueDate: ownerOrInsurer.ownerPassportDate
                ? this.getFormatDate(
                      isOwner
                          ? ownerOrInsurer.ownerPassportDate
                          : ownerOrInsurer.insurerPassportDate
                  )
                : '01.01.2000',
            RegistrationAddressData: {
                daDataAddress: isOwner
                    ? ownerOrInsurer.ownerAddressDaData
                    : ownerOrInsurer.insurerAddressDaData,
                AddressAsString: isOwner
                    ? ownerOrInsurer.ownerCity
                    : ownerOrInsurer.insurerCity,
                HasNoApartment: false,
            },
        };
    }

    // Нужно ли отправлять смс
    public checkStatusSendSms(): boolean {
        if (
            (this.appStatus && this.appStatus === APP_STATUS.New) ||
            this.appStatus === APP_STATUS.WidgetDisplayed ||
            this.appStatus === APP_STATUS.Filled ||
            this.appStatus === APP_STATUS.AuthorizationRequest
        ) {
            return true;
        } else {
            return false;
        }
    }

    private getExperienceDateFromYear(
        experienceStartYear: string,
        birthDate: string,
        licenseDate: string
    ): string {
        return `31.12.${experienceStartYear}`;
    }

    // Сравниваем данные анкеты из запрос getAppInfo с текущей анкетой
    public clientFromSmsEmail(): boolean {
        return false;
    }

    // Проверка есть ли доступ к localStorage
    public isLocalStorageAvailable(): boolean {
        try {
            const testKey = '__test__';
            localStorage.setItem(testKey, testKey);
            localStorage.removeItem(testKey);
            return true;
        } catch (e) {
            return false;
        }
    }

    // Фильтруем офферов по выбранной франшизе
    public filterOffersByFranchise(value: string): void {
        if (value === 'От 5 000 до 50 000') {
            const filteredOffers = this.allOffers.filter((offer: any) => {
                return (
                    offer.offerFranchisePreapprove >= 5000 &&
                    offer.offerFranchisePreapprove <= 50000
                );
            });
            this.offers = filteredOffers;
        } else if (value === 'От 50 001 и выше') {
            const filteredOffers = this.allOffers.filter((offer: any) => {
                return offer.offerFranchisePreapprove >= 50001;
            });
            this.offers = filteredOffers;
        } else if (value === 'Без франшизы') {
            this.offers = this.allOffers.filter((offer: any) => {
                return offer.offerFranchisePreapprove === 0;
            });
        } else {
            this.offers = this.allOffers;
        }
    }
}

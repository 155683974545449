<div class="row">
    <div class="col-10">
        <h5>{{ driverIndex === 0
            ? 'Первый водитель'
            : driverIndex === 1
                ? 'Второй водитель'
                : driverIndex === 2
                    ? 'Третий водитель'
                    : driverIndex === 3
                        ? 'Четвертый водитель'
                        : 'Пятый водитель' }} <button type="button" class="btn btn-link btn-clean-driver btn-sm" (click)="formService.resetDriver(driverIndex)">Очистить</button></h5>
    </div>
    <div class="col-2 text-end" *ngIf="formService.drivers.length > 1">
        <app-confirm-button
            [tooltipTitle]="'Удалить водителя'"
            (changeConform)="$event ? formService.removeDriver(driverIndex) : null"></app-confirm-button>
    </div>
</div>

<div *ngIf="formDriver" [formGroup]="formDriver" class="row driver">
    <!-- 1 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
            formControlName="driverLastName"
            [suggestionPart]="daDataSystemName.SURNAME"
            [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 2 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
            formControlName="driverFirstName"
            [suggestionPart]="daDataSystemName.NAME"
            [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 3 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
            formControlName="driverMiddleName"
            [suggestionPart]="daDataSystemName.PATRONYMIC"
            [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 4 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-form-date-field formControlName="driverBirthDate" [isReadonly]="isLoading"></app-form-date-field>
    </div>
    <!-- 5 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-form-text-field formControlName="driverLicense" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 6 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-form-date-field [hidden]="formDriver.get('rememberOnlyYear')?.value"
                             formControlName="experienceDate" [isReadonly]="isLoading"></app-form-date-field>
        <app-form-number-field [hidden]="!formDriver.get('rememberOnlyYear')?.value"
                             formControlName="experienceYear" [isReadonly]="isLoading"></app-form-number-field>
    </div>
    <!-- 7 -->
    <div class="col-12 col-md-6 col-xxl-6 mb-3">
        <div class="form-check form-switch form-check-reverse">
            <input class="form-check-input" type="checkbox" id="rememberOnlyYear" formControlName="rememberOnlyYear">
            <label class="form-check-label" for="rememberOnlyYear">Помню только год</label>
        </div>
    </div>
    <!-- 8 -->
    <div class="col-12 col-md-6 col-xxl-6 mb-3">
        <div class="form-check form-switch form-check-reverse">
            <input class="form-check-input" type="checkbox" id="licenseWasChanged" formControlName="licenseWasChanged">
            <label class="form-check-label" for="licenseWasChanged">Права менялись в течении года</label>
        </div>
    </div>
    <!-- 9 -->
    <div class="col-12 col-md-6 col-xxl-4 mb-3" [hidden]="!formDriver.get('licenseWasChanged')?.value">
        <app-form-text-field formControlName="oldLicense" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 10 -->
    <div class="col-12 col-md-6 col-xxl-4 mb-3" [hidden]="!formDriver.get('licenseWasChanged')?.value">
        <app-autocomplete
            formControlName="oldDriverLastName"
            [suggestionPart]="daDataSystemName.SURNAME"
            [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 11 -->
    <div class="col-12 col-md-6 mb-3">
        <div class="row checkbox-group m-0">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="label">Пол водителя</div>
            </div>
            <div class="col-3 col-sm-6 col-md-4 text-md-end">
                <div class="form-check form-check-inline">
                    <input formControlName="gender"
                           class="form-check-input"
                           type="radio"
                           value="Male"
                           id="driverGenderMan{{ driverIndex }}">
                    <label class="form-check-label" for="driverGenderMan{{ driverIndex }}">
                        Муж
                    </label>
                </div>
            </div>
            <div class="col-3 col-sm-6 col-md-4 text-md-end">
                <div class="form-check form-check-inline">
                    <input formControlName="gender"
                           class="form-check-input"
                           type="radio"
                           value="Female"
                           id="driverGenderWoman{{ driverIndex }}">
                    <label class="form-check-label" for="driverGenderWoman{{ driverIndex }}">
                        Жен
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- 12 -->
    <div class="col-12 col-md-6 mb-3">
        <div class="row checkbox-group m-0">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="label">Состоит в браке</div>
            </div>
            <div class="col-3 col-sm-6 col-md-4 text-md-end">
                <div class="form-check form-check-inline">
                    <input formControlName="familyStatus"
                           class="form-check-input"
                           type="radio"
                           value="Married"
                           id="familyStatusTrue{{ driverIndex }}">
                    <label class="form-check-label" for="familyStatusTrue{{ driverIndex }}">
                        Да
                    </label>
                </div>
            </div>
            <div class="col-3 col-sm-6 col-md-4 text-md-end">
                <div class="form-check form-check-inline">
                    <input formControlName="familyStatus"
                           class="form-check-input"
                           type="radio"
                           value="Unmarried"
                           id="familyStatusFalse{{ driverIndex }}">
                    <label class="form-check-label" for="familyStatusFalse{{ driverIndex }}">
                        Нет
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- 13 -->
    <div class="col-12 mb-3">
        <app-autocomplete
            formControlName="children"
            label="Количество детей"
            [isReadonly]="isLoading"
            [typeaheadList]="kaskoService.childrenList"
            [typeaheadOptionsInScrollableView]="7"
            [typeaheadMinLength]="0"
            [typeaheadOptionsLimit]="kaskoService.childrenList.length"
            [isDropdown]="true"></app-autocomplete>
    </div>
</div>
<!--<pre>{{ formDriver.value | json }}</pre>-->

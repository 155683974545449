import { Inject, Injectable } from '@angular/core';
import {BehaviorSubject, EMPTY, last, Observable, of} from 'rxjs';
import {
    concatMap,
    delay, expand,
    map,
    take, tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';

// Сервисы
import { FormService } from '../../shared/services/form.service';
import { CacheService } from './cache.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { KaskoService } from '../../shared/services/kasko.service';
import { HttpService } from '../../shared/services/http.service';
import { YandexMetrikaService } from '../../shared/services/yandex-metrika.service';

// Интерфейсы
import { IUrlParams } from '../../shared/interface/url-params.interface';
import { IApplicationId } from '../../shared/interface/app-response.interface';
import { SettingsService } from './settings.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {sizeWindow} from '../../shared/functions/sizeWindow';
import {CalendarHelper} from '../../shared/helpers/calendarHelper';
import {relativeTime} from 'ngx-bootstrap/chronos/duration/humanize';

@Injectable({
    providedIn: 'root'
})
export class AppService extends HttpService {

    constructor(@Inject(HttpClient) protected http: HttpClient,
                protected readonly ym: YandexMetrikaService,
                private readonly settingsService: SettingsService,
                private readonly formService: FormService,
                private readonly navigationService: NavigationService,
                private readonly cacheService: CacheService,
                private readonly kaskoService: KaskoService,
                private readonly bsModalService: BsModalService) {
        super(http, ym);
        this.checkIsArmApplication();
    }

    // тип заявки - новая\пролонгация etc
    private applicationType = 'New';
    // идентификатор клиента на стороне партнёра
    private _cpaClientUid: string | undefined = undefined;
    private _cpaClientUid2: string | undefined = undefined;
    // Анкета открыта из арма
    private _isArmApplication = false;
    // Анкета из арма
    private _armApplication: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
    armApplication$ = this._armApplication.asObservable();

    // URL api окружения
    private apiUrl = environment.apiUrl;
    // URL car info api
    private carInfoApi = environment.carInfoApi;
    // Айди клиента
    public clientId!: string;
    // Если true, то не отправляем смс
    public skipAuth = false;

    private checkIsArmApplication(): void {
        this._isArmApplication = this.isOpenFromArm();

        if (this._isArmApplication) {
            window.addEventListener('message', (ev: MessageEvent) => {
                if (ev.data && ev.data.type && ev.data.type === 'KaskoApplication') {
                    environment.apiKey = ev.data.apikey;
                    environment.cacheService = false;
                    this.settingsService.apiKey = ev.data.apikey;
                    this._cpaClientUid = ev.data.cpaClientUid;
                    this._cpaClientUid2 = ev.data.cpaClientUid2;
                    this.applicationType = ev.data.applicationType;
                    this._armApplication.next(ev.data.application);
                    this._armApplication.complete();
                }
            });

            window.opener.postMessage({ type: 'KaskoApplication'}, '*');
        }
    }

    private isOpenFromArm(): boolean {
        try {
            if (document.referrer == null || document.referrer === '') {
                return false;
            }

            const url = new URL(document.referrer);
            return url.hostname.toLowerCase() === environment.armHost;
        } catch (e) {
            return false;
        }
    }

    get isArmApplication(): boolean {
        return this._isArmApplication;
    }

    // --------------------------------------------------------------------------
    // Запросы вызываются при инициализации, старт запросов в OnInit app.component
    public loadApp(): Observable<any> {
        if (this.isArmApplication)
        {
            return this.loadAppArm();
        }
        const urlParams = this.getUrlParams();

        this.observableModalForIframe();

        // Сначала смотрим какие у нас данные есть, проверяем url, localStorage
        return this.getApplicationIdFromQueryParams()
            .pipe(
                /* Далее создаем application и получаем applicationId
                * Если в url есть applicationId и offersRecivedEmail, то делаем запрос copy, иначе new,
                * В случае если есть параметр applicationId, но нету offersRecivedEmail, то запрос не делаем
                * Нужно только достать данные из getCache для получения сохраненых данных анкеты по applicationId */
                concatMap(() => this.kaskoService.getCarBrands()),
                // Если в кэше есть ID бренда авто, то делаем запрос на получение списка моделей
                concatMap(() => this.kaskoService.getCarModels(this.formService.form.get('carData')?.value.brandId)),
                // concatMap((urlParams: IUrlParams) => this.createApplication(urlParams)),
                // concatMap((urlParams: IUrlParams) => this.setWidgetDisplayed(urlParams)),
                // Получаем clientId, генерируем новый если его нет
                concatMap(() => this.getClientId()),
                map((clientId) => this.clientId = clientId),
                // Получаем кэшированные данные
                concatMap(() => {
                    // если догонялка, пролонгация и т.д.
                    if (urlParams !== null
                        && urlParams.applicationId
                        && urlParams.src
                        && urlParams.offerId !== '0') {
                        return this.cacheService.getCacheByApplicationId();
                    }
                    return this.getClientId();
                }),
                concatMap(() => this.cacheService.getCache(urlParams)),
                concatMap(() => this.kaskoService.getOwnedSinceList()),
                concatMap(() => this.createApplication()),
                // Если мы пришли с осаго, нужен редирект на страницу осаго и далее поиск
                concatMap(() => {
                    if (urlParams.fromOsago || urlParams.offerId || urlParams.src === 'PolicyStarts4Email') {
                        this.navigationService.navigate('offers');
                    }
                    return of(null);
                }),
                take(1)
            );
    }

    public loadAppArm(): Observable<any> {
        return this.kaskoService.getCarBrands()
            .pipe(
                concatMap(() => this.kaskoService.getCarModels(this.formService.form.get('carData')?.value.brandId)),
                take(1)
            );
    }

    // Следим за модальными окнами что бы изменять размер фрейма
    private observableModalForIframe(): void {
        this.bsModalService.onShown.subscribe(() => {
            setTimeout(() => {
                const height = sizeWindow() + 60;
                window.parent.postMessage({ frameHeight: height }, '*');
            }, 0);
        });

        this.bsModalService.onHidden.subscribe(() => {
            setTimeout(() => {
                const height = document.body.scrollHeight;
                window.parent.postMessage({ frameHeight: height }, '*');
            }, 0);
        });
    }

    // Получаем данные из параметров url
    private getApplicationIdFromQueryParams(): Observable<IUrlParams> {
        // Создаем ссылку
        const url: URL = new URL(window.location.href);
        // Получаем параметры
        const params: URLSearchParams = url.searchParams;
        // Получаем параметры из url
        const urlParams: IUrlParams = {
            applicationId: '',
            fromOsago: false
        };

        // Преобразуем все ключи параметров в нижний регистр
        const lowerCaseParams: Record<string, string | null> = {};
        params.forEach((value, key) => {
            lowerCaseParams[key.toLowerCase()] = value;
        });

        // Если есть apiKey в параметрах url, то используем его
        const apiKey: string | null = lowerCaseParams.apikey;
        if (apiKey) {
            this.settingsService.apiKey = apiKey;
            urlParams.apiKey = apiKey;
        }

        // Если есть applicationId в параметрах url, то используем его
        const applicationId: string | null = lowerCaseParams.applicationid;
        if (applicationId) {
            urlParams.applicationId = applicationId;
            this.formService.form.get('applicationId')?.setValue(applicationId);
        }

        // Если есть offersRecivedEmail в параметрах url, то используем его
        const offersRecivedEmail: string | null = lowerCaseParams.offersrecivedemail;
        if (offersRecivedEmail) {
            urlParams.offersRecivedEmail = offersRecivedEmail;
        }

        // Если есть offerId в параметрах url, то используем его
        const offerId: string | null = lowerCaseParams.offerid;
        if (offerId) {
            urlParams.offerId = offerId;
        }

        // Если есть clientId в параметрах url, то используем его
        const clientId: string | null = lowerCaseParams.clientid;
        if (clientId) {
            localStorage.setItem('clientId', clientId);
            urlParams.clientId = clientId;
        }

        // Если есть cpaClientUid в параметрах url, то используем его
        const cpaClientUid: string | null = lowerCaseParams.cpaclientid;
        if (cpaClientUid) {
            urlParams.CpaClientUid = cpaClientUid;
            this.formService.form.get('cpaClientId')?.setValue(cpaClientUid);
        }

        // Если есть cpaClientUid2 в параметрах url, то используем его
        const cpaClientUid2: string | null = lowerCaseParams.cpaclientid2;
        if (cpaClientUid2) {
            urlParams.CpaClientUid2 = cpaClientUid2;
        }

        // Если есть loyalty в параметрах url, то используем его
        const loyalty: string | null = lowerCaseParams.loyalty;
        if (loyalty) {
            this.formService.form.get('cpaClientUid')?.setValue(loyalty);
        }

        // Если мы пришли из осаго
        const fromOsago = lowerCaseParams.fromosago;
        if (fromOsago) {
            urlParams.fromOsago = true;
        }

        // Если мы хотим обойти отправку sms кода
        const skipAuth = lowerCaseParams.skipauth;
        if (skipAuth !== null) {
            urlParams.SkipAuth = true;
        }

        return of(urlParams);
    }


    // Получаем данные из параметров url
    public getUrlParams(): IUrlParams {
        // Создаем ссылку
        const url: URL = new URL(window.location.href);
        // Получаем параметры
        const params: URLSearchParams = url.searchParams;
        // Получаем параметры из url
        const urlParams: IUrlParams = {};

        // Преобразуем все ключи параметров в нижний регистр
        const lowerCaseParams: Record<string, string | null> = {};
        params.forEach((value, key) => {
            lowerCaseParams[key.toLowerCase()] = value;
        });

        // Если есть offersRecivedEmail в параметрах url, то используем его
        const offersRecivedEmail: string | null = lowerCaseParams.offersrecivedemail;
        if (offersRecivedEmail) {
            urlParams.offersRecivedEmail = offersRecivedEmail;
        }

        // Если есть offerId в параметрах url, то используем его
        const offerId: string | null = lowerCaseParams.offerid;
        if (offerId) {
            urlParams.offerId = offerId;
        }

        // Если есть webMasterID в параметрах url, то используем его
        const webMasterID: string | null = lowerCaseParams.webmasterid;
        if (webMasterID) {
            urlParams.WebMasterID = webMasterID;
        }

        // Если есть platformId в параметрах url, то используем его
        const platformID: string | null = lowerCaseParams.platformid;
        if (platformID) {
            urlParams.PlatformID = platformID;
        }

        // Если есть cpaClientUid в параметрах url, то используем его
        const cpaClientUid: string | null = lowerCaseParams.cpaclientuid;
        if (cpaClientUid) {
            urlParams.CpaClientUid = cpaClientUid;
            this.formService.form.get('cpaClientId')?.setValue(cpaClientUid);
        }

        // Если есть cpaClientUid в параметрах url, то используем его
        const cpaClientId: string | null = lowerCaseParams.cpaclientid;
        if (cpaClientId) {
            urlParams.CpaClientId = cpaClientId;
        }

        // Если есть cpaClientUid2 в параметрах url, то используем его
        const cpaClientUid2: string | null = lowerCaseParams.cpaclientuid2;
        if (cpaClientUid2) {
            urlParams.CpaClientUid2 = cpaClientUid2;
        }

        // Если есть loyalty в параметрах url, то используем его
        const loyalty: string | null = lowerCaseParams.loyalty;
        if (loyalty) {
            urlParams.loyalty = loyalty;
        }

        // Если мы пришли из осаго
        const fromOsago = lowerCaseParams.fromosago;
        if (fromOsago) {
            urlParams.fromOsago = true;
        }

        // Если мы хотим обойти отправку sms кода
        const skipAuth = lowerCaseParams.skipauth;
        if (skipAuth !== null) {
            urlParams.SkipAuth = true;
        }

        // Если мы пришли из осаго
        const src = lowerCaseParams.src;
        if (src !== null) {
            urlParams.src = src;
        }

        // Если есть apiKey в параметрах url, то используем его
        const apiKey: string | null = lowerCaseParams.apikey;
        if (apiKey) {
            urlParams.apiKey = apiKey;
        }

        // Если есть applicationId в параметрах url, то используем его
        const applicationId: string | null = lowerCaseParams.applicationid;
        if (applicationId) {
            urlParams.applicationId = applicationId;
        }

        // Если есть clientId в параметрах url, то используем его
        const clientId: string | null = lowerCaseParams.clientid;
        if (clientId) {
            urlParams.clientId = clientId;
        }

        return urlParams;
    }

    // Создание заявки
    public createApplication(urlParams?: IUrlParams): Observable<IApplicationId> {
        const urlParam: URL = new URL(window.location.href);
        const params = this.getUrlParams();

        // Тело запроса
        const request = {
            apiKey: this.settingsService.apiKey,
            productType: 'Kasko',
            applicationType: this.applicationType,
            yandexId: this.ym.clientId,
            yandexCounterId: this.ym.counterId,
            returnClientChannelType: params.src,
            clientId: this.clientId,
            localTime: CalendarHelper.getLocalDateTimeString(),
            utm: this.getUTMData(),
            channelType: 'Widget',
            SourceUrl: urlParam.href,
            platformId: params.PlatformID,
            webMasterID: params.WebMasterID,
            cpaClientUid: this.formService.form.get('cpaClientUid')?.value || this._cpaClientUid || params.loyalty || params.CpaClientUid,
            cpaClientUid2: this.formService.form.get('cpaClientUid2')?.value || this._cpaClientUid2 || params.CpaClientUid2,
            utmSource: params.utmSource,
            utmMedium: params.utmMedium,
            utmTerm: params.utmTerm,
            utmCampaign: params.utmCampaign,
            utmContent: params.utmContent,
            loyalty: params.loyalty
        };
        const url = this.apiUrl + 'app/new';
        return this.post(url, request).pipe(
            map((response: any) => {
                if (response && response.result && response.value) {
                    this.formService.form.get('applicationId')?.setValue(response.value.applicationId);
                    this.changeApplicationId(response.value);
                    this.skipAuth = response.value?.skipAuth;
                    return response.value;
                }
            }),
            concatMap(() => this.setWidgetDisplayed(params)),
        );
    }

    setWidgetDisplayed(urlParams?: IUrlParams): Observable<any> {
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: this.formService.form.get('applicationId')?.value
        };

        if (urlParams && urlParams?.closeNewRequest) {
            this.formService.form.get('applicationId')?.setValue(urlParams.applicationId);
            return new Observable(subscriber => subscriber.next({applicationId: urlParams.applicationId}));
        } else {
            return this.post(this.apiUrl + 'app/SetStatusWidgetDisplayed', request)
                .pipe(take(1));
        }
    }

    // Получаем clientId из localStorage или генерируем новый
    private getClientId(): Observable<string> {
        const clientIdFromLocalStorage = this.kaskoService.isLocalStorageAvailable() ? localStorage?.getItem('clientId') : null;
        // Если clientId есть в localStorage, то возвращаем его
        if (clientIdFromLocalStorage) {
            return of(clientIdFromLocalStorage);
        } else {
            const generateClientId = uuidv4();
            localStorage.setItem('clientId', generateClientId);
            // Иначе, генерируем новый clientId и сохраняем его в localStorage
            return of(generateClientId);
        }
    }

    // Получаем данные UTM
    private getUTMData(): string {
        // Разбор URL и создание объекта URLSearchParams для удобного доступа к параметрам
        const urlObj = new URL(window.location.href);
        const params = new URLSearchParams(urlObj.search);

        // Создаем объект для хранения параметров
        const paramsObj: { [key: string]: string } = {};

        // Заполняем объект параметрами
        params.forEach((value, key) => {
            // Игнорируем параметры, не начинающиеся с 'utm_'
            if (key.startsWith('utm_')) {
                paramsObj[key] = value;
            }
        });

        // Преобразуем объект параметров в строку JSON
        return JSON.stringify(paramsObj);
    }

    // --------------------------------------------------------------------------

    // Применить applicationId к системе
    public changeApplicationId(newApplicationResponse: IApplicationId): void {
        const applicationId = newApplicationResponse?.applicationId;
        this.navigationService.changeQueryParams({applicationId});
        this.formService.form.get('applicationId')?.setValue(applicationId);
    }

    // Применить applicationId к системе
    // public changeAppStatus(appStatus: string): void {
    //     this.kaskoService.appStatus = appStatus;
    // }

    // Запрос на получение данных авто
    public checkCarCharacteristics(): Observable<any> {
        const {value} = this.formService.form;
        const licenseNumber = value.license;
        const applicationId = value.applicationId;

        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId,
            licensePlate: licenseNumber
        };

        return this.post(this.carInfoApi + 'enrichment/CreateTechDataReport', request)
            .pipe(
                // После получения requestId, делаем запрос на получение данных авто
                concatMap((res: any) => {
                        if (res.result && res?.value?.requestId) {
                            this.formService.form.get('carCharacteristicsRequestId')?.setValue(res?.value?.requestId);
                        }
                        return res.result && res?.value?.requestId
                            ? this.getCarCharacteristics()
                            // зачем такие сложности?
                            /* : new Observable((observer: Observer<{ error: boolean } | undefined>) => {
                                observer.next({error: true});
                            }); */
                            : of({error: true});
                    }
                ),
                take(1)
            );
    }

    // Получить данные авто по его номеру
    public getCarCharacteristics(): Observable<any> {
        const requestId = this.formService.form.get('carCharacteristicsRequestId')?.value;
        // Сохраняем идентификатор запроса GetCarCharacteristicsReport в форме
        const request = {
            apiKey: this.settingsService.apiKey,
            requestId,
            UseMasks: true
        };
        // Если status === 'Processing', то делаем повторный запрос пока не получим ответ
        return this.post(this.carInfoApi + 'enrichment/GetTechDataReport', request).pipe(
            expand((res) => {
                if (res?.value?.status === 'Processing') {
                    return of(res).pipe(
                        delay(2000),
                        concatMap(() => this.getCarCharacteristics())
                    );
                } else {
                    return EMPTY;
                }
            }),
            last()
            // mergeMap((res: any) => res?.value?.status === 'Processing' ? EMPTY : of(res)),
            // delay(2000),
            // repeatWhen(() => this.getCarCharacteristics()),
        );
    }

    public error(message: string | null = null, logged = false): void {
        if (message != null) {
            this._errorMessages.push(message);
        }

        if (!logged) {
            this.postLocal('log', {
                applicationId: this.formService.form?.get('applicationId')?.value,
                message
            })
                .pipe(take(1))
                .subscribe({
                    next: () => {
                    },
                    error: () => {
                    }
                });
        }

        console.log('тут раньше в осаго был переход на страницу ошибки');
    }

    // Авторизация через AlfaId
    public authAlfaId(): Observable<any> {
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: this.formService.form.get('applicationId')?.value
        };
        return this.http.post(this.apiUrl + 'Enrichment/GetAlfaIdUrl', request);
    }

    // Авторизация через AlfaId
    public getAlfaIdData(code: string): Observable<any> {
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: this.formService.form.get('applicationId')?.value,
            code
        };
        return this.http.post(this.apiUrl + 'Enrichment/AlfaId', request);
    }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { concatMap, finalize, takeUntil } from 'rxjs/operators';
import {of, Subject, Subscription, timer} from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from '../../../../../environments/environment';

// Перечисления
import { ROUTES } from '../../../../shared/enums/routes.enum';

// Сервисы
import { NavigationService } from '../../../../shared/services/navigation.service';
import { PermitRouteService } from '../../../../core/services/permit-route.service';
import { FormService } from '../../../../shared/services/form.service';
import { KaskoService } from '../../../../shared/services/kasko.service';
import { AppService } from '../../../../core/services/app.service';
import { CacheService } from '../../../../core/services/cache.service';
import { YandexMetrikaService } from '../../../../shared/services/yandex-metrika.service';
import {IOffer} from '../../../../shared/interface/offers.interface';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {

    constructor(public readonly formService: FormService,
                public readonly appService: AppService,
                public readonly kaskoService: KaskoService,
                public readonly navigationService: NavigationService,
                private readonly permitRouteService: PermitRouteService,
                private readonly cacheService: CacheService,
                private readonly ym: YandexMetrikaService) {
        // Мы можем находится на странцие офферов, только если вся форма валидна
        if (!this.permitRouteService.isPermitRoute(ROUTES.Offers)) {
            this.navigationService.navigate(ROUTES.Owner);
        } else {
            // this.loadOffers();
        }
        kaskoService.offers = [];
        kaskoService.allOffers = [];
    }

    // Подписка
    private subscriptions: Subscription[] = [];
    // Индикатор загрузки
    public isLoading = false;
    // Модальное окно
    public modalRef?: BsModalRef;
    // Таймер
    public rxjsTimer = timer(1000, 1000);
    // Следим за таймеом
    public destroy = new Subject();
    // Значение таймера
    public timer!: number;
    // Стартуем поиск офферов
    public getOffersFinish!: boolean;
    // Приоритетная компания (отобьражается в первую очередь)
    public priorityCompany = environment.priorityCompany;
    // Показать остальные компании, если есть приоритетная
    public showOtherCompanies = false;
    // Показываем данные формы для редактирования
    public isShowEditInfo = true;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // При заходе на роут, делаем плавный переход вверх
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.ym.onYandexReachGoal('OFFERS_GOAL');
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // --------------------------------------------------------------------------

    // Проверяем, существует ли приоритетный оффер в спсике офферов
    public get isPriorityOfferExist(): boolean {
        return this.priorityCompany !== null
            && this.kaskoService.offers.find((offer: any) => offer.insurerType === this.priorityCompany);
    }

    // Проверяем, есть ли офферы в списке офферов c параметром isOffline = true
    public get isShowOfflineKasko(): boolean {
        return this.kaskoService.offers.filter((offer: IOffer) => offer.isOffline)?.length > 0;
    }

    // Проверяем, есть ли офферы в списке офферов c параметром isOffline = false
    public get isShowOnlineKasko(): any {
        return this.kaskoService.offers.filter((offer: IOffer) => !offer.isOffline)?.length > 0;
    }

    public get filteredOnlineOffers(): IOffer[] {
        return this.kaskoService.offers.filter((offer: IOffer) =>
            !offer.productName?.includes('за 3') &&
            !offer.productName?.includes('в Десятку') &&
            !offer.productName?.includes('Дай пять') &&
            !offer.isOffline
        );
    }

    public get isShowMiniKasko(): boolean {
        return this.kaskoService.offers.some((offer: IOffer) =>
            offer.productName?.includes('за 3') ||
            offer.productName?.includes('в Десятку') ||
            offer.productName?.includes('Дай пять')
        );
    }

    public get miniKaskoOffers(): IOffer[] {
        return this.kaskoService.offers.filter((offer: IOffer) =>
            offer.productName?.includes('за 3') ||
            offer.productName?.includes('в Десятку') ||
            offer.productName?.includes('Дай пять')
        );
    }

    private loadOffers(changeFilter: boolean = false): void {

        this.getOffersFinish = false;
        this.isLoading = true;

        this.subscriptions.push(
            this.rxjsTimer
                .pipe(
                    takeUntil(this.destroy)
                )
                .subscribe(val => {
                    this.timer = val;

                    if (this.timer >= 60) {
                        this.destroy.next(0);
                        this.destroy.complete();
                    }
                })
        );

        // Сначала проверим, менялись ли данные анкеты, если не менялись, то делаем copy, если менялись то new  и т.д.
        this.subscriptions.push(
            this.kaskoService.getAppInfo()
                .pipe(
                    concatMap(() => this.cacheService.saveCache()),
                    concatMap(() => {
                        const urlParams = this.appService.getUrlParams();
                        // Если мы перешли сюда из страницы собственника, то там уже создавали новую заявку, по этому просто ищем офферов
                        // Если перешли из смс или почты, то создаем новую заявку
                        if (this.kaskoService.clientFromSmsEmail()) {
                            console.log(1);
                            return this.appService.createApplication()
                                .pipe(
                                    concatMap(() => this.kaskoService.sendKaskoApplication()),
                                    concatMap(() => this.kaskoService.authorize()),
                                    concatMap(() => this.cacheService.saveCache()),
                                    concatMap(() => this.kaskoService.sendToInsurersGetOffers()
                                        .pipe(
                                            finalize(() => {
                                                this.isLoading = false;
                                            })
                                        )),
                                );
                        } else if (this.kaskoService.appStatus === 'Authorized') {
                            console.log(2);
                            return this.kaskoService.sendToInsurersGetOffers()
                                .pipe(
                                    finalize(() => {
                                        this.isLoading = false;
                                    })
                                );
                        } else if (this.kaskoService.appStatus === 'WidgetDisplayed') {
                            console.log(3);
                            return this.kaskoService.sendKaskoApplication()
                                .pipe(
                                    concatMap(() => {
                                        if (!this.appService.skipAuth) {
                                            return this.kaskoService.sendVerificationCode(urlParams.fromOsago
                                                || urlParams.offerId === '1'
                                                || urlParams.src === 'PolicyStarts4Email');
                                        } else {
                                            return of(null); // Возвращаем пустой Observable, если SkipAuth не true
                                        }
                                    }),
                                    concatMap(() => this.cacheService.saveCache()),
                                    concatMap(() => this.kaskoService.sendToInsurersGetOffers()
                                        .pipe(
                                            finalize(() => {
                                                this.isLoading = false;
                                            })
                                        )),
                                );
                        } else if (!this.appService.skipAuth) {
                            console.log(4);
                            return this.appService.createApplication()
                                .pipe(
                                    concatMap(() => this.kaskoService.sendKaskoApplication()),
                                    concatMap(() => {
                                        console.log(urlParams.SkipAuth);
                                        if (!urlParams.SkipAuth || this.kaskoService.appStatus !== 'Authorized') {
                                            return this.kaskoService.sendVerificationCode(urlParams.fromOsago
                                                || urlParams.offerId === '1'
                                                || urlParams.src === 'PolicyStarts4Email');
                                        } else {
                                            return of(null); // Возвращаем пустой Observable, если SkipAuth не true
                                        }
                                    }),
                                    concatMap(() => this.cacheService.saveCache()),
                                    concatMap(() => this.kaskoService.sendToInsurersGetOffers()
                                        .pipe(
                                            finalize(() => {
                                                this.isLoading = false;
                                            })
                                        )),
                                );
                        } else if (this.kaskoService.appStatus === 'Authorized') {
                            console.log(5);
                            return this.kaskoService.sendKaskoApplication()
                                .pipe(
                                    concatMap(() => this.kaskoService.sendToInsurersGetOffers()
                                        .pipe(
                                            finalize(() => {
                                                this.isLoading = false;
                                            })
                                        ))
                                );
                        } else {
                            console.log(6);
                            return this.appService.createApplication()
                                .pipe(
                                    concatMap(() => this.kaskoService.sendKaskoApplication()),
                                    concatMap(() => this.cacheService.saveCache()),
                                    concatMap(() => this.kaskoService.sendToInsurersGetOffers()
                                        .pipe(
                                            finalize(() => {
                                                this.isLoading = false;
                                            })
                                        )),
                                );
                        }
                    }),
                    finalize(() => {
                        this.getOffersFinish = true;
                        this.kaskoService.franchiseList = this.kaskoService.getFranchiseList();
                        const filterFrancheseValue = this.formService.formFilter.get('franchise')?.value;
                        this.kaskoService.filterOffersByFranchise(filterFrancheseValue || 'Все предложения');
                    })
                )
                .subscribe((res) => {
                    if (!res.result && res.error?.type === 'StateMachineService.ChangeStatus') {
                        console.log('ошибка связанная с смс кодом');
                        this.ym.onYandexReachGoal('INTERNAL_ERROR_GOAL', res.error);
                        this.formService.form.get('contacts')?.get('smsCode')?.reset();
                        this.navigationService.navigate(ROUTES.Owner);
                    } else if (!res.result && res.error) {
                        this.ym.onYandexReachGoal('INTERNAL_ERROR_GOAL', res.error);
                    }
                })
        );
    }

    // Изменили значение цены в фильтре
    public changeFilterPrice(filterChanged: boolean): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.timer = 0;
        this.destroy.next(0);
        this.destroy.complete();
        this.kaskoService.offers = [];
        this.kaskoService.allOffers = [];
        // Если мы не верифицировали номер, то мы не можем получимть офферов, надо возвратиться на страницу owner
        this.loadOffers(filterChanged);
    }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import {concatMap, finalize, map, mergeMap, take} from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { of, Subscription } from 'rxjs';

// Сервисы
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ValidationService } from '../../../../shared/services/validation.service';
import { CacheService } from '../../../../core/services/cache.service';
import { FormService } from '../../../../shared/services/form.service';
import { KaskoService } from '../../../../shared/services/kasko.service';
import { PermitRouteService } from '../../../../core/services/permit-route.service';

// Перечисления
import { DaData } from '../../../../shared/enums/da-data.enum';
import { ROUTES } from '../../../../shared/enums/routes.enum';

// Интерфейсы
import { IDriver } from '../../../../shared/interface/driver.interface';
import { AppService } from '../../../../core/services/app.service';

// Хелперы
import { compareFormData } from '../../../../shared/helpers/form.helper';
import { NotificationService } from '../../../../shared/services/notification.service';

import { environment } from '../../../../../environments/environment';
import { YandexMetrikaService } from '../../../../shared/services/yandex-metrika.service';
import { SettingsService } from '../../../../core/services/settings.service';


@Component({
    selector: 'app-owner',
    templateUrl: './owner.component.html',
    styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit, OnDestroy {

    constructor(public readonly formService: FormService,
                public readonly kaskoService: KaskoService,
                public readonly appService: AppService,
                public readonly settingsService: SettingsService,
                private readonly navigationService: NavigationService,
                private readonly validationService: ValidationService,
                private readonly cacheService: CacheService,
                private readonly permitRouteService: PermitRouteService,
                private readonly notificationService: NotificationService,
                private readonly ym: YandexMetrikaService) {
        if (!this.permitRouteService.isPermitRoute(ROUTES.Owner)) {
            this.navigationService.navigate(ROUTES.Drivers);
        } else {
            this.subscription.add(this.cacheService.saveCache().subscribe());
        }
    }

    // Форма страхователя
    public formOwner = (this.formService.form.get('owner') as UntypedFormGroup);
    // Форма страховщика
    public formInsurer = (this.formService.form.get('insurer') as UntypedFormGroup);
    // Форма контактов
    public formContacts = (this.formService.form.get('contacts') as UntypedFormGroup);
    // Флаг страхователь является собственником авто
    public ownerIsInsured = (this.formService.form.get('ownerIsInsured') as UntypedFormControl);
    // Policy
    public policy = (this.formService.form.get('policy') as UntypedFormControl);
    // Сообщение валидации для контрола policy
    public policyValidationMessage = {
        required: 'Ознакомьтесь с условиями сервиса и политикой конфиденциальности для того, чтобы перейти на следующий шаг'
    };
    // Индикатор загрузки
    public isLoading = false;
    // DaData системные имена
    public daDataSystemName = DaData;
    // Подписка на контрол
    private subscription: Subscription = new Subscription();
    // Показываем таймер
    public isShowTimer = false;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // При заходе на роут, делаем плавный переход вверх
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.ym.onYandexReachGoal('OWNER_GOAL');

        this.sunscribeIsInsurerControl();

    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // --------------------------------------------------------------------------

    // Отправка формы
    public onSubmit(): void {
        this.formOwner.markAllAsTouched();
        this.formInsurer.markAllAsTouched();
        this.formContacts.markAllAsTouched();
        this.policy.markAllAsTouched();
        const isValidCarDataPage = this.validationService.validationAutoInfoPage();
        const isValidOwnerPage = this.validationService.validationOwnerPage();
        const isValidContacts = this.validationService.validationContacts();

        if (!isValidCarDataPage || !isValidOwnerPage || !isValidContacts) {
            return;
        }

        this.navigationService.navigate(ROUTES.Offers);
    }

    // Отправляем данные анкеты и получаем смс код
    public sendSmsCode(ignoreValidation: boolean = false): void {

        if (!ignoreValidation) {
            this.formOwner.markAllAsTouched();
            this.formInsurer.markAllAsTouched();
            this.formContacts.markAllAsTouched();
            this.policy.markAllAsTouched();
            const isValidCarDataPage = this.validationService.validationAutoInfoPage();
            const isValidOwnerPage = this.validationService.validationOwnerPage();
            const isValidContacts = this.validationService.validationContacts();

            if (!isValidCarDataPage || !isValidOwnerPage || !isValidContacts) {
                return;
            }
        }

        this.isLoading = true;
        this.isShowTimer = true;

        const urlParams = this.appService.getUrlParams();

        console.log(this.appService.skipAuth);

        if (this.appService.skipAuth) {
            this.navigationService.navigate(ROUTES.Offers);
        } else {
            this.subscription.add(
                this.kaskoService.getAppInfo()
                    .pipe(
                        concatMap(() => this.cacheService.saveCache()),
                        mergeMap(() => !this.kaskoService.isWidgetStatus
                            ? this.appService.createApplication() : of(null)),
                        mergeMap(() => this.kaskoService.sendKaskoApplication()),
                        mergeMap(() => this.cacheService.saveCache()),
                        mergeMap(() => this.kaskoService.sendVerificationCode(urlParams.fromOsago)),
                        map((response: any) => {
                            console.log(response);
                            if (response.result && response.result && response?.value?.confirmed) {
                                this.kaskoService.phoneStatus = response.result && response?.value?.confirmed;
                                this.formContacts.get('smsCode')?.reset();
                                this.formContacts.get('smsCode')?.disable();
                                this.navigationService.navigate(ROUTES.Offers);
                            } else if (response.error) {
                                this.notificationService.error('Ошибка', response.error.message, 4000);
                            } else {
                                this.formContacts.get('smsCode')?.enable();
                                this.formContacts.get('smsCode')?.markAsUntouched();
                                // window.scrollTo(0, document.body.scrollHeight);
                            }
                        })
                    )
                    .subscribe((res) => {
                        this.isLoading = false;
                        this.isShowTimer = false;
                    })
            );
        }


    }

    // Условие при котором мы делаем новую заявку
    private resolveAppStatus(): boolean {
        return this.kaskoService.appStatus === 'SentToInsurers'
            || this.kaskoService.appStatus === 'OfferRecived'
            || this.kaskoService.appStatus === 'AuthorizationRequest';
    }

    // Отправляем данные анкеты и получаем смс код
    public sendSmsCodeRepeat(): void {
        this.isLoading = true;
        this.isShowTimer = true;

        this.kaskoService.sendVerificationCode()
            .pipe(
                map((res) => res.result ? this.cacheService.saveCache() && of(res) : of(res)),
                finalize(() => this.isLoading = false)
            )
            .subscribe((response: any) => {
                if (response.result && response.result && response?.value?.confirmed) {
                    this.kaskoService.phoneStatus = response.result && response?.value?.confirmed;
                    this.formContacts.get('smsCode')?.reset();
                    this.formContacts.get('smsCode')?.disable();
                    this.navigationService.navigate(ROUTES.Offers);
                } else if (response.error) {
                    this.notificationService.error('Ошибка', response.error.message, 4000);
                } else {
                    this.formContacts.get('smsCode')?.enable();
                    this.formContacts.get('smsCode')?.markAsUntouched();
                    window.scrollTo(0, document.body.scrollHeight);
                }
                // this.isLoading = false;
                // this.isShowTimer = false;
            });
    }

    // Получаем (Фамилия И.О.) для заголовка в контролле ownerIsInsured
    public getOwnerNameTitle(): string {
        const {value} = this.formOwner;
        // Возвращаем значение, только в случае если страхователь является собственником авто
        if (this.ownerIsInsured.value
            && value.ownerLastName
            && value.ownerFirstName?.length
            && value.ownerFirstName[0]
            && value.ownerMiddleName?.length
            && value.ownerMiddleName[0]) {
            return '(' + value.ownerLastName + ' ' + value.ownerFirstName[0] + '. ' + value.ownerMiddleName[0] + '.)';
        } else {
            return '';
        }
    }

    // Получаем (Фамилия И.О.) для заголовка в контролле ownerIsInsured
    public getDriverTitle(driver: any): string {
        // Возвращаем значение, только в случае если страхователь является собственником авто
        if (driver.driverLastName
            && driver.driverFirstName?.length
            && driver.driverFirstName[0]) {
            const lastName = driver.driverLastName;
            const firstName = driver.driverFirstName[0] + '.';
            const middleName = driver.driverMiddleName?.length && driver.driverMiddleName[0] ? driver.driverMiddleName[0] + '.' : '';
            return lastName + ' ' + firstName + ' ' + middleName;
        } else {
            return '';
        }
    }

    // Событие ввода смс кода
    public inputSmsCode(event: Event): void {
        const smsCode = (event.target as HTMLInputElement)?.value;
        if (smsCode.length === 4) {
            this.isLoading = true;
            this.kaskoService.verifyCode(smsCode)
                .pipe(
                    finalize(() => this.isLoading = false),
                    take(1)
                ).subscribe((res) => {
                if (res.result) {
                    this.formContacts.get('smsCode')?.reset();
                    this.formContacts.get('smsCode')?.disable();
                    this.navigationService.navigate(ROUTES.Offers);
                } else {
                    console.log('Вы ввели неверный смс код');
                    this.formContacts.get('smsCode')?.setErrors({errorCode: true});
                    this.formContacts.get('smsCode')?.markAsTouched();
                }
            });
        }
    }

    // Выбрать водителя
    public selectDriver(driver: IDriver, isOwner: boolean = true): void {
        const { controls } = isOwner ? this.formOwner : this.formInsurer;
        if (isOwner) {
            controls.ownerLastName?.setValue(driver.driverLastName);
            controls.ownerFirstName?.setValue(driver.driverFirstName);
            controls.ownerMiddleName?.setValue(driver.driverMiddleName);
            controls.ownerBirthDate?.setValue(driver.driverBirthDate);
            controls.passportLicense?.reset();
            controls.ownerPassportDate?.reset();
            controls.ownerCity?.reset();
        } else {
            controls.insurerLastName?.setValue(driver.driverLastName);
            controls.insurerFirstName?.setValue(driver.driverFirstName);
            controls.insurerMiddleName?.setValue(driver.driverMiddleName);
            controls.insurerBirthDate?.setValue(driver.driverBirthDate);
            controls.passportLicense?.reset();
            controls.insurerPassportDate?.reset();
            controls.insurerCity?.reset();
        }
    }

    // Выбрать водителя
    public resetInfoDriver(isOwner: boolean = true): void {
        const {controls} = isOwner ? this.formOwner : this.formInsurer;
        if (isOwner) {
            controls.ownerLastName?.setValue('');
            controls.ownerFirstName?.setValue('');
            controls.ownerMiddleName?.setValue('');
            controls.ownerBirthDate?.setValue('');
            controls.passportLicense?.reset();
            controls.ownerPassportDate?.reset();
            controls.ownerCity?.reset();
            this.formOwner.markAsUntouched();
        } else {
            controls.insurerLastName?.setValue('');
            controls.insurerFirstName?.setValue('');
            controls.insurerMiddleName?.setValue('');
            controls.insurerBirthDate?.setValue('');
            controls.passportLicense?.reset();
            controls.insurerPassportDate?.reset();
            controls.insurerCity?.reset();
            this.formInsurer.markAsUntouched();
        }
    }

    // Получаем данные таймера
    public changeTimerValue(event: number): void {
        if (event === 0) {
            this.isShowTimer = false;
        }
    }

    public checkActiveDriver(driver: IDriver | null, isOwner: boolean): boolean {
        const { value } = isOwner ? this.formOwner : this.formInsurer;
        if (driver) {
            return isOwner
                ? (driver.driverFirstName === value.ownerFirstName
                    && driver.driverLastName ===  value.ownerLastName
                    && driver.driverMiddleName ===  value.ownerMiddleName
                    && driver.driverBirthDate ===  value.ownerBirthDate)
                : (driver.driverFirstName === value.insurerFirstName
                    && driver.driverLastName ===  value.insurerLastName
                    && driver.driverMiddleName ===  value.insurerMiddleName
                    && driver.driverBirthDate ===  value.insurerBirthDate);
        } else {
            const drivers = this.formService.form.get('drivers')?.value;
            const findDriver = drivers.find((item: IDriver) => isOwner
                ? (item.driverFirstName === value.ownerFirstName
                    && item.driverLastName ===  value.ownerLastName
                    && item.driverMiddleName ===  value.ownerMiddleName
                    && item.driverBirthDate ===  value.ownerBirthDate)
                : (item.driverFirstName === value.insurerFirstName
                    && item.driverLastName ===  value.insurerLastName
                    && item.driverMiddleName ===  value.insurerMiddleName
                    && item.driverBirthDate ===  value.insurerBirthDate));
            return !findDriver;
        }
    }

    // При смене значения контрола - стразователь является собственником авто
    private sunscribeIsInsurerControl(): void {
        this.subscription.add(
            this.ownerIsInsured.valueChanges
                .subscribe((value) => {
                    this.formInsurer.markAsUntouched();
                })
        );
    }

}

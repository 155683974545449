// version: 1.11
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { filter, finalize } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import { ROUTES } from '../../../kaskoV2/src/app/shared/enums/routes.enum';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {Title} from '@angular/platform-browser';

// Сервисы
import { FormService } from '../../../kaskoV2/src/app/shared/services/form.service';
import { AppService } from '../../../kaskoV2/src/app/core/services/app.service';
import { KaskoService } from '../../../kaskoV2/src/app/shared/services/kasko.service';
import { CacheService } from '../../../kaskoV2/src/app/core/services/cache.service';
import { NavigationService } from '../../../kaskoV2/src/app/shared/services/navigation.service';

@Component({
    selector: 'app-root',
    templateUrl: '../../../landings/kasko-alfa/app/app.component.html',
    styleUrls: ['../../../landings/kasko-alfa/app/app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
    constructor(public readonly app: AppService,
                public readonly formService: FormService,
                public readonly cacheService: CacheService,
                public readonly kaskoService: KaskoService,
                private readonly router: Router,
                private readonly route: ActivatedRoute,
                private readonly navigationService: NavigationService,
                private readonly titleService: Title) {
        setTheme('bs5');
    }

    // Заголовок сайта
    private title = 'Alfabank KASKO';
    private faviconHref = 'assets/landingFiles/images/favicon-16x16.png';
    private favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

    // Индикатор загрузки
    public isLoading!: boolean;

    // События проверки интернет соединения
    private onlineEvent!: Observable<Event>;
    private offlineEvent!: Observable<Event>;

    // Подписка
    private subscriptions: Subscription[] = [];

    // Статус инетрнет соединения
    public connectionStatus!: string;

    // Текщий щаг
    public stepValue!: number;

    // Показываем мини лендинг
    public isModalShown = false;
    public modalRef!: BsModalRef;
    // Показываем степпер
    public isShowStepper = true;
    // Имя из AlfaId
    public nameFromAlfaId = '';

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.titleService.setTitle(this.title);
        if (this.favIcon != null) {
            this.favIcon.href = this.faviconHref;
        }
        this.isLoading = true;
        this.app.loadApp()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe();

        this.openMiniLanding();
        this.checkNetworkStatus();
        this.checkStepValue();
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    // --------------------------------------------------------------------------

    // Инициализация скрытого функционала
    public isAdmin(): boolean {
        const isAdmin = this.kaskoService.isLocalStorageAvailable() ? localStorage?.getItem('isHideConfigInsapp') : null;
        return isAdmin !== null && isAdmin !== '';
    }

    // Проверка инетрнет соединения
    private checkNetworkStatus(): void {
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
            this.reloadPage();
            this.connectionStatus = 'online';
        }));

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            this.connectionStatus = 'offline';
        }));
    }

    // Обновить страницу
    public reloadPage(): void {
        window.location.reload();
    }

    // Определяем текщий шаг
    private checkStepValue(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((router) => {
                if ((router as NavigationStart).url.search(ROUTES.AutoInfoPage) > 0) {
                    this.stepValue = 1;
                    this.isShowStepper = true;
                } else if ((router as NavigationStart).url.search(ROUTES.Drivers) > 0) {
                    this.stepValue = 2;
                    this.isShowStepper = true;
                } else if ((router as NavigationStart).url.search(ROUTES.Owner) > 0) {
                    this.stepValue = 3;
                    this.isShowStepper = true;
                } else if ((router as NavigationStart).url.search(ROUTES.Offers) > 0) {
                    this.stepValue = 4;
                    this.isShowStepper = true;
                } else {
                    this.isShowStepper = false;
                }
            });
    }

    // Показываем header только на главной странице
    public isShowHeader(): boolean {
        return this.router.url.search(ROUTES.AutoInfoPage) < 0
            && this.router.url.search(ROUTES.Drivers) < 0
            && this.router.url.search(ROUTES.Owner) < 0
            && this.router.url.search(ROUTES.Offers) < 0
            && this.router.url.search(ROUTES.CreatePolicy) < 0
            && this.router.url.search(ROUTES.Success) < 0;
    }

    // Навигатор кнопки "Назад"
    public backBtnNavigate(): void {
        if (this.router.url.search(ROUTES.AutoInfoPage) > 0) {
            this.navigationService.navigate(ROUTES.MainPage);
        } else if (this.router.url.search(ROUTES.Drivers) > 0) {
            this.navigationService.navigate(ROUTES.AutoInfoPage);
        } else if (this.router.url.search(ROUTES.Owner) > 0) {
            this.navigationService.navigate(ROUTES.Drivers);
        } else if (this.router.url.search(ROUTES.Offers) > 0) {
            this.navigationService.navigate(ROUTES.Owner);
        } else {

        }
    }

    // Открыть миниледнинг
    private openMiniLanding(): void {
        this.subscriptions.push(
            this.route.queryParams.subscribe((params) => {
                if (params && params.apiKey) {
                    const isShowMiniLanding = localStorage.getItem('isShowMiniLanding');
                    this.isModalShown = isShowMiniLanding !== '0' && window.innerWidth <= 540;
                    addEventListener('resize', () => {
                        this.isModalShown = localStorage.getItem('isShowMiniLanding') !== '0' && window.innerWidth <= 540;
                    });
                } else if (params && params.code) {
                    this.checkAlfaIdCode(params.code);
                } else if (params && params.applicationId) {
                    this.formService.form.get('applicationId')?.setValue(params.applicationId);
                }
            })
        );
    }

    // Закрыть минилендинг
    public hideModal(): void {
        this.autoShownModal?.hide();
        localStorage.setItem('isShowMiniLanding', '0');
    }

    // Сбрасываем состояние отображение минилендинга
    public resetShowMiniLanding(): void {
        localStorage.setItem('isShowMiniLanding', '1');
    }

    // Авторизация через AlfaId
    public authAlfaId(): void {
        this.subscriptions.push(
            this.app.authAlfaId()
                .subscribe((res) => {
                    if (res.result) {
                        window.location.href = res.value;
                    }
                })
        );
    }

    // Проверяем code от AlfaId и делаем запрос на полечение данных
    private checkAlfaIdCode(code: string): void {
        this.subscriptions.push(
            this.app.getAlfaIdData(code)
                .subscribe((res: any) => {

                    // const res = {
                    //     "value": {
                    //         "lastName": "Медведев",
                    //         "firstName": "Владимир",
                    //         "middleName": "Александрович",
                    //         "birthDate": "1963-02-25",
                    //         "gender": "мужчина",
                    //         "phone": "79114587744",
                    //         "passportSeries": "0011",
                    //         "passportNumber": "057013",
                    //         "regAddress": "Россия, Москва, улица Красная, 1",
                    //         "applicationId": null,
                    //         "error": null
                    //     },
                    //     "result": true,
                    //     "error": null
                    // };

                    if (res.result && !res.value.error) {

                        if (res.value?.firstName && res.value?.lastName) {
                            this.nameFromAlfaId = [
                                res.value?.lastName,
                                res.value?.firstName?.charAt(0).toUpperCase() + '.',
                                res.value?.middleName?.charAt(0).toUpperCase() + '.'
                            ].join(' ');
                        }

                        const birthDate = res.value.birthDate;

                        const parts = birthDate.split('-');
                        const year = parts[0];
                        const month = parts[1];
                        const day = parts[2];

                        const formattedDate = day + '.' + month + '.' + year;

                        const data = {
                            driver: {
                                birthDate: formattedDate,
                                driverLicense: {
                                    isForeignLicense: false,
                                    licenseIssueDate: '',
                                    licenseNumber: res.value.passportNumber,
                                    licenseSeries: res.value.passportSeries
                                },
                                experienceStartDate: '',
                                rememberOnlyYear: false
                            },
                            address: res.value.regAddress,
                        };

                        // Применяем данные виз AlfaId в форму
                        this.formService.drivers.controls[0]?.get('driverFirstName')?.setValue(res.value.firstName);
                        this.formService.drivers.controls[0]?.get('driverLastName')?.setValue(res.value.lastName);
                        this.formService.drivers.controls[0]?.get('driverMiddleName')?.setValue(res.value.middleName);
                        this.formService.drivers.controls[0]?.get('driverBirthDate')?.setValue(data.driver.birthDate);
                        this.formService.drivers.controls[0]?.get('gender')?.setValue(res.value.gender.toLowerCase() === 'мужчина' ? 'Male' : 'Female');
                        this.formService.form.get('contacts')?.get('phone')?.setValue(res.value.phone.substring(1));
                        this.formService.form.get('owner')?.get('passportLicense')?.setValue(res.value.passportSeries + res.value.passportNumber);
                        this.formService.form.get('owner')?.get('ownerCity')?.setValue(res.value.regAddress);
                        this.formService.form.get('owner')?.get('ownerLastName')?.setValue(res.value.lastName);
                        this.formService.form.get('owner')?.get('ownerFirstName')?.setValue(res.value.firstName);
                        this.formService.form.get('owner')?.get('ownerMiddleName')?.setValue(res.value.middleName);
                        this.formService.form.get('owner')?.get('ownerBirthDate')?.setValue(formattedDate);
                    }
                })
        );
    }

}
